import React, { useCallback, useRef, useState } from 'react';
import s from './fieldList.module.css';
import { FieldContainer } from './field/fieldContainer';
import { InputWithBtn } from '../../../common/inputWithBtn/inputWithBtn';
import { Input } from '../../../common/input/input';
import { filterList } from '../../../../core/utils/functions/filterList';

export const FieldList = ({
	fieldList,
	fieldName,
	fieldType,
	setFieldName,
	setFieldType,
	createField,
}) => {
	const defaultCheckedRadio = useRef();
	const [openList, setOpenList] = useState(false);

	const courts = [{ type: 'Арбитражный' }];
	const [courtList, setCourtList] = useState(courts);

	const validatorInputValue = (e) => {
		const reg = /(([\wа-яё\(\)]+((-|\s|\.|,)?))+)/i;
		const currentValue = e.target.value;
		const lastSymbol = currentValue[currentValue.length - 1];
		const lastButOneSymbol = currentValue[currentValue.length - 2];

		if (!(currentValue.length - 1)) {
			return reg.test(currentValue) ? setFieldName(currentValue) : '';
		}

		if (
			(reg.test(`${lastButOneSymbol}${lastSymbol}`) &&
				/[\.-\wа-яё\s,\(\)]/i.test(lastSymbol)) ||
			/\.\s|,\s/.test(`${lastButOneSymbol}${lastSymbol}`)
		) {
			setFieldName(currentValue.trimStart());
		}
	};

	const addField = () => {
		if (fieldType === 'court') {
			const currentCourtName = courts.find((court) => court.type === fieldName);
			if (!currentCourtName) {
				console.log('Court not found'); // Message not found
				return;
			}
			setOpenList(false);
		}
		createField();
		defaultCheckedRadio.current.checked = true;
		setFieldType(defaultCheckedRadio.current.value);
	};

	const clickFieldType = useCallback(
		(e) => {
			if (e.target.value === 'courtAddress') {
				setFieldName('Адрес суда');
			}

			if (fieldType === 'courtAddress') {
				setFieldName('');
			}
			setFieldType(e.target.value);
		},
		[fieldType]
	);

	const handlerFieldName = (e) => {
		setCourtList(filterList(e.target.value, courts, 'type'));
		setFieldName(e.target.value);
	};

	const handlerCourtSelection = (e) => {
		setFieldName(e.target.innerText);
		setCourtList(courts);
		setOpenList(false);
	};

	// console.log('list');
	return (
		<div className={s.container}>
			<h3 className={`subtitle ${s.subtitle}`}>Добавление полей</h3>

			<div className={s.blockType}>
				<span>Тип поля:</span>

				<label className={s.blockRadio}>
					<Input
						refInput={defaultCheckedRadio}
						type='radio'
						classes={s.inputRadio}
						inputName='typeField'
						inputValue={'text'}
						clickFunction={clickFieldType}
						defaultCheckedINput={true}
					/>
					Строка
				</label>
				<label className={s.blockRadio}>
					<Input
						type='radio'
						classes={s.inputRadio}
						inputName='typeField'
						inputValue={'number'}
						clickFunction={clickFieldType}
					/>
					Число
				</label>
				<label className={s.blockRadio}>
					<Input
						type='radio'
						classes={s.inputRadio}
						inputName='typeField'
						inputValue={'date'}
						clickFunction={clickFieldType}
					/>
					Дата
				</label>
				<label className={s.blockRadio}>
					<Input
						type='radio'
						classes={s.inputRadio}
						inputName='typeField'
						inputValue={'money'}
						clickFunction={clickFieldType}
					/>
					Сумма
				</label>
				<label className={s.blockRadio}>
					<Input
						type='radio'
						classes={s.inputRadio}
						inputName='typeField'
						inputValue={'court'}
						clickFunction={clickFieldType}
					/>
					Подсудность
				</label>
				<label className={s.blockRadio}>
					<Input
						type='radio'
						classes={s.inputRadio}
						inputName='typeField'
						inputValue={'courtAddress'}
						clickFunction={clickFieldType}
					/>
					Адрес суда
				</label>
			</div>

			<InputWithBtn
				inputValue={fieldName}
				inputClickFunction={() => {
					if (fieldType === 'court') setOpenList(true);
				}}
				inputChangeFunction={(e) => {
					if (fieldType === 'court') {
						handlerFieldName(e);
						return;
					}
					if (fieldType === 'courtAddress') {
						return;
					}
					validatorInputValue(e);
				}}
				keyDownEnterFunction={addField}
				placeholder='Задайте вопрос для поля'
				btnText='Добавить'
				btnClickFunction={addField}
			/>
			{openList && (
				<ul className={s.list}>
					{courtList.map((court, i) => (
						<li key={i} className={s.item} onClick={handlerCourtSelection}>
							{`${court.type}`}
						</li>
					))}
				</ul>
			)}

			<div className={s.listContainer}>
				{fieldList
					// .filter((field) => field.type !== 'signature')
					.filter(
						(field) => field.type !== 'image' && field.type !== 'signature'
					)
					.map((field) => (
						<FieldContainer key={field.key} field={field} />
					))}
			</div>
		</div>
	);
};
