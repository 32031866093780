import { CompositeDecorator } from 'draft-js';
import { HashtagDecorator } from './hashtagDecorator/hashtagDecorator';
import { ImageDecorator } from './imageDecorator/imageDecorator';

export const decoratorList = new CompositeDecorator([
	{
		strategy: hashtagStrategy,
		component: HashtagDecorator,
	},
	{
		strategy: imageStrategy,
		component: ImageDecorator,
	},
]);

function imageStrategy(contentBlock, callback, contentState) {
	contentBlock.findEntityRanges((character) => {
		const entityKey = character.getEntity();
		return (
			entityKey !== null &&
			(contentState.getEntity(entityKey).getType() === 'image' ||
				contentState.getEntity(entityKey).getType() === 'signature')
		);
	}, callback);
}

function hashtagStrategy(contentBlock, callback, contentState) {
	contentBlock.findEntityRanges((character) => {
		const entityKey = character.getEntity();

		return (
			entityKey !== null &&
			contentState.getEntity(entityKey).getType() !== 'image' &&
			contentState.getEntity(entityKey).getType() !== 'signature'
		);
	}, callback);
}

// function hashtagStrategy(contentBlock, callback, contentState) {
// 	const HASHTAG_REGEX = /#(?:[\wа-яё\(\)][\s\S]*?[\wа-яё\.\)])#/gi;

// 	findWithRegex(HASHTAG_REGEX, contentBlock, callback);
// }

// function findWithRegex(regex, contentBlock, callback) {
// 	const text = contentBlock.getText();
// 	let matchArr, start;
// 	while ((matchArr = regex.exec(text)) !== null) {
// 		start = matchArr.index;
// 		callback(start, start + matchArr[0].length);
// 	}
// }
