import { useState } from 'react';
import s from './onlineWallet.module.css';
import { ReactComponent as YooMoney } from '../../../../../../../img/yoomoney.svg';
import { ReactComponent as Yandex } from '../../../../../../../img/yandex.svg';
import { ReactComponent as WebMoney } from '../../../../../../../img/webmoney.svg';
import { ReactComponent as VkPay } from '../../../../../../../img/vkPay.svg';

export const OnlineWallet = () => {
	const Y_CASHBOX = 'Y_CASHBOX';
	const YANDEX_MONEY = 'YANDEX_MONEY';
	const WEB_MONEY = 'WEB_MONEY';
	const VK_PAY = 'VK_PAY';

	const [methodPay, setMethodPay] = useState('');

	return (
		<ul className={s.list}>
			<MethodPay
				icon={<YooMoney />}
				name={'Юкасса'}
				method={Y_CASHBOX}
				methodPay={methodPay}
				clickFn={() => setMethodPay(Y_CASHBOX)}
			/>
			<MethodPay
				icon={<Yandex />}
				name={'Яндекс кошелек'}
				method={YANDEX_MONEY}
				methodPay={methodPay}
				clickFn={() => setMethodPay(YANDEX_MONEY)}
			/>
			<MethodPay
				icon={<WebMoney />}
				name={'WebMoney'}
				method={WEB_MONEY}
				methodPay={methodPay}
				clickFn={() => setMethodPay(WEB_MONEY)}
			/>

			<MethodPay
				icon={<VkPay />}
				name={'ВК pay'}
				method={VK_PAY}
				methodPay={methodPay}
				clickFn={() => setMethodPay(VK_PAY)}
			/>
		</ul>
	);
};

function MethodPay({ icon, name, method, methodPay, clickFn }) {
	return (
		<li
			className={`${s.method} ${method === methodPay && s.active}`}
			onClick={clickFn}>
			{icon}
			{name}
		</li>
	);
}
