import { useEffect } from 'react';
import { useNavigate, Route, Routes, useHref } from 'react-router-dom';
import { useAuth } from '../../../../core/hooks/useAuth';
import s from './categoriesPageMobile.module.css';
import { MenuItemMobile } from '../../../common/menuItem/menuItemMobile/menuItemMobile';
import { MainMenu } from '../../../common/mainMenu/mainMenu';
import { Section } from '../../../common/section/section';
import { ReactComponent as Law } from '../../../../img/icon/law.svg';
import { ReactComponent as Home } from '../../../../img/icon/home.svg';
import { ReactComponent as Car } from '../../../../img/icon/car.svg';
import { ReactComponent as Family } from '../../../../img/icon/family.svg';
import { ReactComponent as Money } from '../../../../img/icon/money.svg';
import { ReactComponent as Work } from '../../../../img/icon/work.svg';
import { ReactComponent as Arrow } from '../../../../img/arrow2.svg';
import { Favorites } from '../../officePage/userOffice/favorites/favorites';
import { ServiceCard } from '../../../common/serviceCard/serviceCard';
import { ServicePage } from '../../servicePage/servicePage';

export const CategoriesPageMobile = () => {
	const navigate = useNavigate();
	const { isAuth } = useAuth();

	const href = useHref();

	// const [currentCategory, setCurrentCategory] = useState('');

	const handlerNavigation = (path, state) => {
		// console.log(state);
		navigate(path, state);
	};

	return (
		<Section className={s.section}>
			{href === '/categories' ? (
				<h3 className={s.title}>Категории</h3>
			) : (
				<button className={s.btnBack} onClick={() => navigate(-1)}>
					<Arrow /> Вернуться
				</button>
			)}

			{href === '/categories' && (
				<MainMenu
					classes={s.categoryList}
					list={
						<>
							<MenuItemMobile
								icon={<Law />}
								name={'Ущерб'}
								clickFunction={() => handlerNavigation('damage')}
							/>

							<MenuItemMobile
								icon={<Home />}
								name={'Недвижимость и имущество'}
								clickFunction={() => handlerNavigation('property')}
							/>

							<MenuItemMobile
								icon={<Car />}
								name={'Транспорт'}
								clickFunction={() => handlerNavigation('transport')}
							/>

							<MenuItemMobile
								icon={<Family />}
								name={'Семья и дети'}
								clickFunction={() => handlerNavigation('family')}
							/>

							<MenuItemMobile
								icon={<Money />}
								name={'Пособия и выплаты'}
								clickFunction={() => handlerNavigation('benefitsAndPayments')}
							/>

							<MenuItemMobile
								icon={<Work />}
								name={'Работа'}
								clickFunction={() => handlerNavigation('work')}
							/>
						</>
					}
				/>
			)}

			<Routes>
				<Route
					path='damage/*'
					element={
						<div>
							{href === '/categories/damage' && (
								<ServiceCard
									classes={s.serviceCard}
									clickFunction={() => handlerNavigation('damage/serviceName')}
								/>
							)}

							<Routes>
								<Route path=':serviceName' element={<ServicePage />} />
							</Routes>
						</div>
					}
				/>
				<Route path='property' element={<div>1</div>} />
				<Route path='transport' element={<div>2</div>} />
				<Route path='family' element={<div>3</div>} />
				<Route path='benefitsAndPayments' element={<div>4</div>} />
				<Route path='work' element={<div>5</div>} />
			</Routes>
		</Section>
	);
};
