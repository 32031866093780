import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import s from './bottomMenuMobile.module.css';
import { CircleWithShadow } from '../circleWithShadow/circleWithShadow';
import { FlagLine } from '../flagLine/flagLine';
import { ReactComponent as AllCategories } from '../../../img/icon/allCategories.svg';
import { ReactComponent as BookmarkIcon } from '../../../img/bookmarkIcon.svg';
import { ReactComponent as BellIcon } from '../../../img/bellIcon.svg';
import woman from '../../../img/womenMobile.png';
import { ReactComponent as UserIcon } from '../../../img/userIcon.svg';
// import { Login } from '../login/login';
import { useAuth } from '../../../core/hooks/useAuth';
import { selectUser } from '../../../store/selectors';
import { setUser } from '../../../store/features/user/userSlice';
import { setStateDocument } from '../../../store/features/document/documentSlice';
import { Authentication } from '../authentication/authentication';

export const BottomMenuMobile = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { isAuth } = useAuth();

	const userData = useSelector(selectUser);
	const [loginOpen, setLoginOpen] = useState(false);

	const handlerFavorites = () => navigate('/userOffice/favorites');
	const handlerMainPage = () => navigate('/');
	const handlerUserOffice = () => navigate('/userOffice');

	return (
		<div className={s.container}>
			<nav className={s.menu}>
				<Item
					icon={
						<CircleWithShadow
							icon={<AllCategories />}
							classes={s.allCategories}
						/>
					}
					text={'Каталог'}
				/>

				<Item
					icon={<BookmarkIcon />}
					text={'Избранное'}
					onClick={handlerFavorites}
				/>
				<Item
					icon={<img src={woman} alt='icon' />}
					text={'Главная'}
					onClick={handlerMainPage}
				/>
				<Item icon={<BellIcon />} text={'Уведомления'} />
				<Item
					icon={<UserIcon />}
					text={isAuth ? `${userData.firstName}` : 'Войти'}
					onClick={
						isAuth
							? userData.userType === '0'
								? handlerUserOffice // user
								: () => {} // admin or moderator
							: () => setLoginOpen(true)
					}
				/>
			</nav>

			{loginOpen ? (
				<Authentication closeWindow={() => setLoginOpen(false)} />
			) : (
				<></>
			)}

			<FlagLine />
		</div>
	);
};

function Item({ icon, text, onClick }) {
	return (
		<div className={s.item} onClick={onClick}>
			{icon}
			<p>{text}</p>
		</div>
	);
}
