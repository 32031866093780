import s from './logo.module.css';
import { ReactComponent as Women } from '../../../img/woman.svg';

export const Logo = ({ classes }) => {
	return (
		<div className={`${s.container} ${classes}`}>
			{/* <Women /> */}
			{/* <svg></svg> */}
			<p>
				<span>1</span> юридический.рф
			</p>
		</div>
	);
};
