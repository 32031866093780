export const insertSignatureIntoDoc = (doc, signature) => {
	// const content = doc.editorContent;
	// const contentBlocks = content.blocks;

	const signatureField = doc.fieldList.find(
		(field) => field.type === 'signature'
	);

	const entityMapValues = Object.values(doc.editorContent.entityMap);

	const entitySignature = entityMapValues.find(
		(entity) => entity.data.key === signatureField.key
	);
	entitySignature.data.src = signature;

	// doc.fieldList.forEach((field) => {
	// 	if (field.type === 'signature') {
	// if (signature) {
	// const entityMapValues = Object.values(doc.editorContent.entityMap);

	// const entitySignature = entityMapValues.find(
	// 	(entity) => entity.data.key === field.key
	// );
	// entitySignature.data.src = signature;
	// }
	// }

	// if (field.type !== 'signature') {
	// 	contentBlocks.forEach((block) => {
	// 		const currentField = new RegExp(`#${escapeRegExp(field.name)}#`, 'g');

	// 		const result = block.text.replace(currentField, field.value);
	// 		block.text = result;
	// 	});
	// }
	// });

	return doc;
};
