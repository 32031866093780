import { useContext } from 'react';
import s from './titleBlock.module.css';
import { WindowWidthContext } from '../../../app/app';
import { ReactComponent as Time } from '../../../../img/icon/time.svg';
import { ReactComponent as World } from '../../../../img/icon/world.svg';
import { ReactComponent as Min } from '../../../../img/icon/min.svg';
import { ReactComponent as LinkArrowFlag } from '../../../../img/linkArrowFlag.svg';
import { Link } from '../../../common/link/link';
import { Button } from '../../../common/button/button';

export const TitleBlock = () => {
	const { windowWidth, windowWidthPhone } = useContext(WindowWidthContext);

	return (
		<div className={s.container}>
			<div className={s.titleBlock}>
				<p className={s.logo}>
					<span>1</span> юридический.рф
				</p>

				<p>Юридические услуги в твоем кармане</p>

				<p>Онлайн сервис для подачи в суды и иные гос. органы</p>

				<div className={s.iconsBlock}>
					<div>
						<Time />
						<p>В любое время</p>
					</div>

					<div>
						<World />
						<p>Из любой точки</p>
					</div>

					<div>
						<Min />
						<p>Всего 15 минут</p>
					</div>
				</div>
			</div>

			<div className={s.infoCards}>
				<div>
					<p>Хотите найти проверенного юриста?</p>

					<Link className={s.link}>
						Посмотреть рейтинг <LinkArrowFlag />
					</Link>
				</div>

				<div>
					<p>Не нашли нужный документ?</p>

					<p>Оставьте заявку и мы разработаем документ для вас</p>

					<Link className={s.link}>
						Оставить заявку <LinkArrowFlag />
					</Link>
				</div>
			</div>
		</div>
	);
};
