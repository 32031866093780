import { useSelector } from 'react-redux';
import { selectUser } from '../../store/selectors';
import { getCookie } from '../utils/functions/getCookie';

export const useAuth = () => {
	const { id, email } = useSelector(selectUser);

	return {
		isAuth: !!id || getCookie('userName'),
		id,
		email,
	};
};
