import s from './hashtagDecorator.module.css';

export const HashtagDecorator = (props) => {
	const { contentState, entityKey, offsetKey } = props;
	const { name, value } = contentState.getEntity(entityKey).getData();

	return (
		<span className={s.field} data-offset-key={offsetKey}>
			{`#${name}#`}
		</span>
	);
};
