import { useContext } from 'react';
import s from './mainPage.module.css';
import bgrFlag from '../../../img/bgrFlag.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectServices } from '../../../store/selectors';
import { useAuth } from '../../../core/hooks/useAuth';
import { WindowWidthContext } from '../../app/app.jsx';
import { NewsBlock } from './newsBlock/newsBlock';
import { SearchBlock } from './searchBlock/searchBlock.jsx';

import { Section } from '../../common/section/section.jsx';
import { WhyUs } from './whyUs/whyUs.jsx';
import { TitleBlock } from './titleBlock/titleBlock.jsx';
import { ServiceBlock } from './serviceBlock/serviceBlock.jsx';
import { UsefulBlock } from './usefulBlock/usefulBlock.jsx';
import { GovernmentBlock } from './governmentBlock/governmentBlock.jsx';
import { PopularBlock } from './popularBlock/popularBlock.jsx';

export const MainPage = () => {
	const { windowWidth, windowWidthPhone } = useContext(WindowWidthContext);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const serviceList = useSelector(selectServices).serviceList;
	const { isAuth } = useAuth();

	// console.log(serviceList);
	return (
		<div className={s.container}>
			<div className={`${s.bgr}`}>
				<Section className={s.bgrFlag}>
					{windowWidth > windowWidthPhone ? (
						<>
							<TitleBlock />
							<ServiceBlock />
							<SearchBlock />
						</>
					) : (
						<>
							<SearchBlock />
							<TitleBlock />
							<ServiceBlock />
						</>
					)}

					<img src={bgrFlag} className={s.bgrFlagImg} alt='flag' />
				</Section>
			</div>

			<Section>
				<PopularBlock />
			</Section>

			<Section>
				<NewsBlock />
			</Section>

			<Section>
				<UsefulBlock />
			</Section>

			<Section>
				<WhyUs />
			</Section>

			<Section>
				<GovernmentBlock />
			</Section>
		</div>
	);
};
