import { useState } from 'react';
import s from './paymentByCard.module.css';
import { ReactComponent as Mir } from '../../../../../../../img/Mir.svg';
import { ReactComponent as Visa } from '../../../../../../../img/Visa.svg';
import { ReactComponent as MasterCard } from '../../../../../../../img/MasterCard.svg';
import { Input } from '../../../../../../common/input/input';

export const PaymentByCard = () => {
	const [cardNumber, setCardNumber] = useState();

	return (
		<div placeholder='' className={s.container}>
			<div className={s.headRow}>
				<h3 className={s.title}>Введите данные банковской карты</h3>

				<div className={s.paySystems}>
					<Mir />
					<Visa />
					<MasterCard />
				</div>
			</div>

			<Input
				type={'text'}
				classes={s.input}
				maxLength={16}
				inputValue={cardNumber}
				changeFunction={(e) => setCardNumber(e.target.value)}
				placeholder='Номер карты'
			/>

			<div className={s.lastRow}>
				{/* <div> */}
				<div className={s.cardData}>
					<Input classes={s.input} placeholder='ММ' />
					<p>месяц</p>
				</div>

				<div className={s.cardData}>
					<Input classes={s.input} placeholder='ГГ' />
					<p>год</p>
				</div>
				{/* </div> */}

				<div className={s.cardData}>
					<p>Последние 3 цифры с обратной стороны</p>
					<Input classes={s.input} placeholder='CVV' />
				</div>
			</div>
		</div>
	);
};
