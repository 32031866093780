import s from './documentBlock.module.css';
// import { Button } from '../../../../common/button/button';
import { TextEditor } from '../../textEditor/textEditor';
import { useCallback, useContext, useState } from 'react';
// import { EditorContext } from '../contentContainer';
import { ModalWindow } from '../../../../common/modalWindow/modalWindow';
import { OpenFileContainer } from '../../openFile/openFileContainer';
import { CloseDoc } from '../closeDoc/closeDoc';
import { ImgEditorContext } from '../../textEditor/toolbar/imgEditorControl/imgEditorControl';
// import { ReactComponent as OpenFileIcon } from '../../../../../img/button/openFile.svg';

export const DocumentBlock = () => {
	// const { doc, closeDocument } = useContext(EditorContext);
	const [openModalOpenFile, setOpenModalOpenFile] = useState(false);
	const [openModalCloseDoc, setOpenModalCloseDoc] = useState(false);

	const [openImgSetting, setOpenImgSetting] = useState(false);
	const [imgEntityKey, setImgEntityKey] = useState('');

	const openDoc = useCallback(() => setOpenModalOpenFile(true), []);

	const handlerCloseDocument = () => {
		setOpenModalCloseDoc(true);
	};

	const imgEditorContextValue = {
		openImgSetting,
		setOpenImgSetting,
		imgEntityKey,
		setImgEntityKey,
	};

	return (
		<div>
			{/* <div className={s.flexBlock}> */}
			{/* <h3 className={s.docName}>
				{doc.name ? doc.name : 'Название документа'}
			</h3> */}

			{/* <div className={s.docBtns}>
					<Button
						text={
							<>
								<OpenFileIcon /> {`Открыть документ`}
							</>
						}
						classes={s.btn}
						clickFunction={openDoc}
					/>

					<Button
						text={'Закрыть документ'}
						classes={s.btn}
						clickFunction={handlerCloseDocument}
					/>
				</div> */}
			{/* </div> */}

			<div className={s.editorBlock}>
				{/* <h3 className={`${s.subtitle}`}>
					Документ{' '}
					<span className={s.littleText}>
						(Введите текст документа, когда потребуется информация клиента,
						перейдите к добаленияю полей, чтобы задать вопрос.)
					</span>
				</h3> */}

				<ImgEditorContext.Provider value={imgEditorContextValue}>
					<TextEditor />
				</ImgEditorContext.Provider>
			</div>

			{openModalOpenFile && (
				<ModalWindow
					clickFunction={() => setOpenModalOpenFile(!openModalOpenFile)}
					content={
						<OpenFileContainer setOpenModalOpenFile={setOpenModalOpenFile} />
					}
				/>
			)}

			{openModalCloseDoc && (
				<ModalWindow
					clickFunction={() => setOpenModalCloseDoc(!openModalCloseDoc)}
					content={<CloseDoc setOpenModalCloseDoc={setOpenModalCloseDoc} />}
				/>
			)}
		</div>
	);
};
