import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import s from './formDocumentPage.module.css';
import {
	// templateApi,
	userDocApi,
} from '../../../api/api';
import { socket } from '../../../api/socket';
import { FormCard } from '../../common/formCard/formCard';
import { selectDocument } from '../../../store/selectors';
import { Input } from '../../common/input/input';
import {
	setStateDocument,
	setValueForFieldWithTypeCourtOrCourtAddress,
} from '../../../store/features/document/documentSlice';
import { Button } from '../../common/button/button';
import { ScreenHeightContainer } from '../../common/screenHeightContainer/screenHeightContainer';
import { createUserDocument } from '../../../store/features/document/thunks';
import { Navigation } from '../../common/navigation/navigation';
import { ModalWindow } from '../../common/modalWindow/modalWindow';
import { RequestResultWindow } from '../../common/requestResultWindow/requestResultWindow';
import { Content } from './content';
import { filterList } from '../../../core/utils/functions/filterList';
// import { escapeRegExp, set } from 'lodash';
import { creatorDocAndStateFromTemplate } from '../../../core/utils/functions/creatorDocAndStateFromTemplate';
import { insertValuesIntoDocument } from '../../../core/utils/functions/insertValuesIntoDocument';
import { Timer } from '../../common/timer/timer';

export const FormDocumentPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const userDoc = useSelector(selectDocument);

	const navLinkList = useMemo(
		() => [
			{
				text: 'Главная',
				path: '/',
			},
			{
				text: 'Услуги',
				path: '/services',
			},
			{
				text: userDoc.name,
			},
		],
		[userDoc.name]
	);

	const [openRequestResultWindow, setOpenRequestResultWindow] = useState(false);
	const [requestResult, setRequestResult] = useState('');

	const [regions, setRegions] = useState([]);
	const [regionField, setRegionField] = useState('');
	const [regionList, setRegionList] = useState([]);
	const [openList, setOpenList] = useState(false);

	const [openCreateDocWindow, setOpenCreateDocWindow] = useState(false);

	const [dataQR, setDataQR] = useState('');
	const [timerStartAt, setTimerStartAt] = useState(false);

	const [isSignedDoc, setIsSignedDoc] = useState(false);

	const handlerRegionField = (e) => {
		setRegionList(filterList(e.target.value, regions, 'region'));
		setRegionField(e.target.value);
	};

	const handlerItemSelection = (e) => {
		setRegionField(e.target.innerText);
		setRegionList(regions);

		userDocApi.getCourt(e.target.innerText).then((res) => {
			dispatch(
				setValueForFieldWithTypeCourtOrCourtAddress({
					type: 'court',
					value: res.data[0].name,
				})
			);
			dispatch(
				setValueForFieldWithTypeCourtOrCourtAddress({
					type: 'courtAddress',
					value: res.data[0].address,
				})
			);
		});

		setOpenList(false);
	};

	// console.log(Date.now());

	const handlerSignDoc = () => {
		fillingOutDoc();

		dispatch(createUserDocument())
			.then((res) => {
				console.log(res.payload.data);

				setOpenCreateDocWindow(false);
				setDataQR(res.payload.data);
				setTimerStartAt(Date.now());
			})
			.catch((err) => console.log(err));
	};

	const closeModalWindow = () => {
		setOpenCreateDocWindow(!openCreateDocWindow);
		setOpenCreateDocWindow(false);
	};

	const closeSavingResultModalWindow = () => {
		setOpenRequestResultWindow(!openRequestResultWindow);
		setRequestResult('');
		navigate('/office');
	};

	const getNewQR = () => {
		userDocApi
			.getUserDoc({
				href: dataQR.signnumber,
				update: true,
			})
			.then((res) => {
				console.log(res);

				setDataQR(res.data);
				setTimerStartAt(Date.now());
			});
	};

	const closeQRModalWindow = () => {
		// setDataQR(''); // if navigate, delete
		navigate('/office');
	};

	function fillingOutDoc() {
		const checkRegionField = regions.find(
			(region) => region.region === regionField
		);

		if (checkRegionField === undefined || openList) {
			setRegionField('');
			setRegionList(regions);
			setOpenList(false);
			return;
		}
		const docAndState = creatorDocAndStateFromTemplate(userDoc);

		const updateDoc = insertValuesIntoDocument(docAndState.doc);
		dispatch(setStateDocument(updateDoc));
	}

	function createDocument() {
		fillingOutDoc();

		dispatch(createUserDocument()).then((res) => {
			if (res.payload.statusText === 'OK') {
				setRequestResult('Успешно!');
				setOpenRequestResultWindow(true);
				return;
			}

			setRequestResult(res.payload.message);
			setOpenRequestResultWindow(true);
		});

		dispatch(setStateDocument());
	}

	useEffect(() => {
		userDocApi.getRegionList().then((res) => {
			// console.log(res);
			setRegions(res.data);
			setRegionList(res.data);
		});
	}, []);

	useEffect(() => {
		socket.on('isSignedDocument', (res) => {
			// console.log(res);
			setIsSignedDoc(true);
		});
	}, []);

	return (
		<ScreenHeightContainer>
			<Navigation linkList={navLinkList} />

			<FormCard
				classes={s.form}
				subtitle={userDoc.name}
				// submitFunction={createDocument}
				submitFunction={() => setOpenCreateDocWindow(true)}>
				<>
					<div className={s.listContainer}>
						<label className={`${s.label}`}>
							Выберите регион
							<Input
								classes={s.input}
								type={'text'}
								inputValue={regionField}
								clickFunction={() => setOpenList(true)}
								changeFunction={handlerRegionField}
								placeholder={'Выберите регион'}
								required={true}
							/>
						</label>
						{openList && (
							<ul className={s.list}>
								{regionList.map((region, i) => (
									<li key={i} className={s.item} onClick={handlerItemSelection}>
										{`${region.region}`}
									</li>
								))}
							</ul>
						)}
					</div>

					<Content fieldList={userDoc.fieldList} regions={regions} />
				</>
			</FormCard>

			{openCreateDocWindow && (
				<ModalWindow
					clickFunction={closeModalWindow}
					content={
						<FormCard classes={s.form} subtitle={'Подписать документ?'}>
							<div className={s.btnContainer}>
								<Button
									text={'Создать документ'}
									clickFunction={createDocument}
								/>

								<Button text={'Подписать'} clickFunction={handlerSignDoc} />
							</div>
						</FormCard>
					}
				/>
			)}

			{openRequestResultWindow && (
				<RequestResultWindow
					clickFunction={closeSavingResultModalWindow}
					message={requestResult}
				/>
			)}

			{dataQR && (
				<ModalWindow
					clickFunction={closeQRModalWindow}
					content={
						<div className={s.qrContainer}>
							{isSignedDoc ? (
								<div>Документ подписан!</div>
							) : timerStartAt ? (
								<>
									<img src={dataQR.qrcodebase64} alt='QR' />
									<Timer
										text={'Время действия QR кода'}
										timerTime={45 * 1000}
										startAt={timerStartAt}
										setStartAt={setTimerStartAt}
									/>
								</>
							) : (
								<>
									<p>Время действия QR кода вышло.</p>

									<div className={s.btnContainer}>
										<Button
											clickFunction={getNewQR}
											text={'Запросить QR код'}
										/>

										<Button
											clickFunction={closeQRModalWindow}
											text={'Подписать позднее'}
										/>
									</div>
								</>
							)}
						</div>
					}
				/>
			)}
		</ScreenHeightContainer>
	);
};
