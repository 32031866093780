import {
	useNavigate,
	Route,
	Routes,
	useHref,
	useLocation,
} from 'react-router-dom';
import s from './purchasesMobile.module.css';
import { ServiceName } from '../serviceName/serviceName';
import { ReactComponent as Icon } from '../../../../../../img/icon/rub.svg';
import { ServiceStatus } from '../serviceStatus/serviceStatus';

export const PurchasesMobile = ({ handlerNavigation, href }) => {
	const purchaseList = [
		{
			id: 1,
			category: 'Товары и услуги',
			name: 'Претензия на возврат денежных средств',
			listOfChange: [
				{ date: '10.09.2023 12:31', status: 'Заполнение анкеты' },
				{ date: '10.09.2023 12:31', status: 'Заполнение анкеты' },
				{ date: '10.09.2023 12:31', status: 'Заполнение анкеты' },
			],
		},

		{
			id: 2,
			category: 'Товары и услуги',
			name: 'Претензия на возврат денежных средств',
			listOfChange: [
				{ date: '10.09.2023 12:31', status: 'Заполнение анкеты' },
				{ date: '10.09.2023 12:31', status: 'Заполнение анкеты' },
				{ date: '10.09.2023 12:31', status: 'Заполнение анкеты' },
			],
		},
	];

	return (
		<div className={s.container}>
			{href === '/userOffice/purchases' && (
				<>
					<div className={s.headRow}>
						<h3 className={s.title}>Мои покупки</h3>
						<p>Сортировать: по дате</p>
					</div>

					<ul className={s.list}>
						{purchaseList.map((el, i) => (
							<ServiceName
								key={el.id}
								i={el.id}
								category={el.category}
								name={el.name}
								icon={<Icon />}
								clickFunction={() =>
									handlerNavigation(`purchases/${el.id}`, {
										state: el,
									})
								}
							/>
						))}
					</ul>
				</>
			)}

			<Routes>
				<Route path=':serviceId' element={<ServiceInfo />} />
			</Routes>
		</div>
	);
};

function ServiceInfo() {
	const { state } = useLocation();
	// console.log(state);

	return (
		<div className={s.serviceInfo}>
			<div className={s.serviceTitleBlock}>
				<h5>{state.category}</h5>
				<h4> {state.name}</h4>
			</div>

			<ServiceStatus classes={s.serviceStatus} />
		</div>
	);
}
