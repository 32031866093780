import { EditorState, Modifier } from 'draft-js';

export const creatorEditorStateWithEntity = (
	editorState,
	type,
	name,
	value = ''
) => {
	const currentContent = editorState.getCurrentContent();
	const selection = editorState.getSelection();

	// Entity creation is not used when field is re-added.
	const contentStateWithEntity = currentContent.createEntity(
		type === 'signature' ? 'image' : type,
		'IMMUTABLE'
	);
	const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

	currentContent.replaceEntityData(
		entityKey,
		type === 'image' || type === 'signature' //????????????????????????
			? {
					key: entityKey,
					name: name,
					type: type,
					src: value,
					width: type === 'signature' ? '80px' : '50px', ///////////
					height: type === 'signature' ? '40px' : '50px', //////////
			  }
			: {
					key: entityKey,
					name: name,
					type: type,
					value: value,
			  }
	);

	const newContent = Modifier.replaceText(
		currentContent,
		selection,
		type === 'image' || type === 'signature' ? ' ' : `#${name}#`,
		null, // ['UNDERLINE'],['BOLD','ITALIC']
		entityKey
	);

	const newEditorState = EditorState.push(
		editorState,
		newContent,
		'insert-characters'
	);

	return newEditorState;
};
