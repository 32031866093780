import s from './paymentOnAccount.module.css';
import { ReactComponent as EditIcon } from '../../../../../../../img/editIcon.svg';

export const PaymentOnAccount = () => {
	return (
		<div className={s.container}>
			<div className={s.payData}>
				<div className={s.headRow}>
					<p>Название организации: ООО Саженки</p>

					<div className={s.edit}>
						<EditIcon /> <p>Редактировать</p>
					</div>
				</div>

				<p>ИНН: 5468514646</p>
				<p>ОГРН: 5468514646</p>
				<p>ОГРНИП: 5468514646</p>
				<p>Юридический адрес: г. Нижний Новгород ул. Дьяконова д. 14 кв 12</p>
				<p>Название банка: Сбербанк</p>
				<p>БИК Банка: 548421</p>
				<p>Номер счета: 35685432165423136545216351</p>
				<p>Корр. счет: 35685432165423136545216351</p>
			</div>
		</div>
	);
};
