import { useState } from 'react';
import s from './appeal.module.css';
import { Button } from '../../../../../common/button/button';
import { Input } from '../../../../../common/input/input';
import { ReactComponent as ArrowFlag } from '../../../../../../img/linkArrowFlag.svg';
import { ReactComponent as Paperclip } from '../../../../../../img/paperclip.svg';
// import { ReactComponent as SearchIcon } from '../../../../../img/searchIcon.svg';

export const Appeal = () => {
	const [file, setFile] = useState('Прикрепить файл');

	return (
		<div className={s.appeal}>
			<div className={s.appealHead}>
				<p>Обращение: #24654 “Работа сервиса”</p>

				<p>08.02.2024</p>
			</div>

			<p>
				<span className={s.subtitle}>Тема:</span> Заявление заполнено, но до сих
				пор не отправлено
			</p>

			<p className={s.subtitle}>Вопрос:</p>

			<p>
				Могут, на основании Федерального закона от 24.07.2023 N 385-ФЗ "О
				внесении изменений в Федеральный закон "Об образовании в Российской
				Федерации" и статью 4 Федерального закона "О науке и государственной
				научно-технической политике". Следует учесть, что порядок допуска таких
				лиц к работе поручено установить Минпросвещения России по согласованию с
				Минтрудом России. В настоящее время порядок еще не принят. Обоснование:
				Воспитатель является педагогическим работником (подраздел 2 разд. I
				Номенклатуры, утв. Постановлением Правительства РФ от 21.02.2022 N 225).
			</p>

			<p className={s.subtitle}>Прикрепленные файлы:</p>

			<textarea rows={10} maxLength={400} placeholder='Ответ'></textarea>

			<div className={s.fileContainer}>
				<label className={s.file}>
					<Input
						type={'file'}
						changeFunction={(e) => setFile(e.target.files[0].name)}
					/>

					<Paperclip />

					{file}
				</label>

				<Button
					type={'submit'}
					classes={s.btn}
					text={
						<>
							Отправить <ArrowFlag />
						</>
					}
				/>
			</div>
		</div>
	);
};
