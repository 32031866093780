import { memo } from 'react';
import s from './buttonField.module.css';

export const ButtonField = ({ type, text, clickFunction }) => {
	return (
		<button
			className={text === '+' ? `${s.green}` : `${s.red}`}
			type={type}
			onClick={clickFunction}>
			{text}
		</button>
	);
};
