import { CircleWithShadow } from '../../../../common/circleWithShadow/circleWithShadow';
import s from './whyUsCard.module.css';

export const WhyUsCard = ({ icon, title, text }) => {
	return (
		<div className={s.card}>
			<CircleWithShadow classes={s.img} icon={icon} />

			<h4 className={s.title}>{title}</h4>

			<p className={s.text}>{text}</p>
		</div>
	);
};
