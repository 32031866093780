import { useContext } from 'react';
import s from './content.module.css';
import { EditorContext } from './contentContainer';
// import { ModalWindow } from '../../../common/modalWindow/modalWindow';
import { RequestResultWindow } from '../../../common/requestResultWindow/requestResultWindow';
import { DocumentBlock } from './documentBlock/documentBlock';
import { StickyBlock } from './stickyBlock/stickyBlock';
import { DocumentMenu } from './documentMenu/documentMenu';

export const Content = ({
	openRequestResultWindow,
	setOpenRequestResultWindow,
	requestResult,
	setRequestResult,
}) => {
	const { doc, closeDocument } = useContext(EditorContext);

	return (
		<section className={`${s.container}`}>
			<DocumentMenu />

			<h3 className={s.docName}>
				{doc.name ? doc.name : 'Название документа'}
			</h3>

			<span className={s.littleText}>
				(Введите текст документа, когда потребуется информация клиента,
				перейдите к добаленияю полей, чтобы задать вопрос.)
			</span>

			<div className={s.docContainer}>
				<DocumentBlock />

				<StickyBlock />
			</div>

			{openRequestResultWindow && (
				<RequestResultWindow
					clickFunction={() => {
						setOpenRequestResultWindow(!openRequestResultWindow);
						setRequestResult('');
					}}
					message={requestResult}
				/>
			)}
		</section>
	);
};
