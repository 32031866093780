import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import s from './menuCard.module.css';
import { ReactComponent as Exit } from '../../../../../img/exit.svg';
import { deleteCookie } from '../../../../../core/utils/functions/deleteCookie';
import { setUser } from '../../../../../store/features/user/userSlice';
import { setStateDocument } from '../../../../../store/features/document/documentSlice';

export const MenuCard = ({ setMenuOpen }) => {
	const navigate = useNavigate();

	const dispatch = useDispatch();

	const handlerUserOffice = () => {
		navigate('/userOffice/userData');
		setMenuOpen(false);
	};

	const handlerPurchases = () => {
		navigate('/userOffice/purchases');
		setMenuOpen(false);
	};

	const handlerSupport = () => {
		navigate('/userOffice/support');
		setMenuOpen(false);
	};

	const handlerExit = () => {
		deleteCookie('userAuth');
		dispatch(setUser());
		dispatch(setStateDocument());
	};

	return (
		<ul className={s.menu}>
			<li className={s.item} onClick={handlerUserOffice}>
				<a href='#' className={s.link}>
					Личные данные
				</a>
			</li>
			<li className={s.item} onClick={handlerPurchases}>
				<a href='#' className={s.link}>
					Покупки
				</a>
			</li>
			<li className={s.item}>
				<a href='#' className={s.link}>
					Конфиденциальность{' '}
				</a>
			</li>
			<li className={s.item} onClick={handlerSupport}>
				<a href='#' className={s.link}>
					Поддержка{' '}
				</a>
			</li>
			<li className={s.item} onClick={handlerExit}>
				<a href='#' className={s.link}>
					<Exit className={s.icon} />
					Выйти{' '}
				</a>
			</li>
		</ul>
	);
};
