import React, { useState } from 'react';
import s from './saveAs.module.css';
import { Button } from '../../../common/button/button';
import { Input } from '../../../common/input/input';
import { FormCard } from '../../../common/formCard/formCard';
import { filterList } from '../../../../core/utils/functions/filterList';
import { Textarea } from '../../../common/textarea/textarea';

export const SaveAs = ({ dirList, serviceList, saveDocument }) => {
	const [openPathList, setOpenPathList] = useState(false);
	const [pathList, setPathList] = useState(dirList);
	const [nameDocValue, setNameDocValue] = useState('');
	const [pathDocValue, setPathDocValue] = useState('');
	const [descriptionDocValue, setDescriptionDocValue] = useState('');
	const [priceDocValue, setPriceDocValue] = useState('');

	const handlerNameInput = (e) => {
		setNameDocValue(e.target.value.trimStart());
	};

	const handlerPathInput = (e) => {
		setPathList(filterList(e.target.value, dirList, 'path'));
		setPathDocValue(e.target.value);
	};

	const handlerPathSelection = (e) => {
		setPathDocValue(e.target.innerText);
		setPathList(dirList);
		setOpenPathList(!openPathList);
	};

	const handlerDescriptionTextarea = (e) => {
		setDescriptionDocValue(e.target.value.trimStart());
	};
	const handlerPriceInput = (e) => {
		setPriceDocValue(e.target.value.trimStart());
	};
	const handlerSaveDocument = () => {
		const path = dirList.find((folder) => folder.path === pathDocValue);

		const doc = serviceList.find(
			(doc) => doc.name === nameDocValue && doc.path === pathDocValue
		);

		if (path === undefined) {
			setPathDocValue('');
			setPathList(dirList);
			setOpenPathList(false);
			return;
		}

		if (doc !== undefined) {
			setNameDocValue('');
			setPathDocValue('');
			return;
		}
		saveDocument(
			nameDocValue,
			pathDocValue,
			descriptionDocValue,
			priceDocValue
		);
	};

	// console.log('rer', pathList);
	return (
		<FormCard title={'Сохранить как'} submitFunction={handlerSaveDocument}>
			<>
				<Input
					classes={s.input}
					inputValue={nameDocValue}
					changeFunction={handlerNameInput}
					placeholder='Введите имя документа'
					required={true}
				/>

				<div className={s.listContainer}>
					<Input
						classes={s.input}
						inputValue={pathDocValue}
						clickFunction={() => setOpenPathList(true)}
						changeFunction={handlerPathInput}
						placeholder='Выбрать папку'
						required={true}
					/>
					{openPathList && (
						<ul className={s.list}>
							{pathList.map((folder) => (
								<li
									key={folder.id}
									className={s.item}
									onClick={handlerPathSelection}>
									{folder.path}
								</li>
							))}
						</ul>
					)}
				</div>

				<Textarea
					classes={s.input}
					rows={5}
					textareaValue={descriptionDocValue}
					changeFunction={handlerDescriptionTextarea}
					placeholder={'Описание'}
				/>

				<Input
					classes={s.input}
					type={'number'}
					inputValue={priceDocValue}
					changeFunction={handlerPriceInput}
					placeholder='Введите стоимость'
					required={true}
				/>

				<Button classes={s.btn} type={'submit'} text={'Сохранить'} />
			</>
		</FormCard>
	);
};
