import React, { useState } from 'react';
import s from './openFile.module.css';
import { Button } from '../../../common/button/button';
import { Input } from '../../../common/input/input';
// import { ContainerInputWithBtn } from '../../../common/containerInputWithBtn/containerInputWithBtn';
import { FormCard } from '../../../common/formCard/formCard';
import { InputWithBtn } from '../../../common/inputWithBtn/inputWithBtn';

export const OpenFile = ({
	serviceList,
	fileDocValue,
	setFileDocValue,
	openFile,
}) => {
	const [fileList, setFileList] = useState(serviceList);
	const [openList, setOpenList] = useState(false);

	const handlerFileInput = (e) => {
		setFileList(filterFileList(e.target.value));
		setFileDocValue(e.target.value);
	};

	const handlerFileSelection = (e) => {
		setFileDocValue(e.target.innerText);
		setFileList(filterFileList(e.target.innerText));
		setOpenList(!openList);
	};

	function filterFileList(value) {
		return serviceList.filter((folder) =>
			`${folder.name} (${folder.path})`
				.toLowerCase()
				.includes(value.toLocaleLowerCase())
		);
	}

	// console.log(serviceList);
	return (
		<FormCard title={'Открыть файл'} submitFunction={openFile}>
			<>
				<InputWithBtn
					// classesContainer={s.containerInputWithBtn}
					inputValue={fileDocValue}
					inputClickFunction={() => setOpenList(true)}
					inputChangeFunction={handlerFileInput}
					placeholder={'Выберите файл'}
					btnType={'submit'}
					btnText={'Открыть'}
				/>
				{openList && (
					<ul className={s.list}>
						{fileList.map((file) => (
							<li
								key={file.id}
								className={s.item}
								onClick={handlerFileSelection}>
								{`${file.name} (${file.path})`}
							</li>
						))}
					</ul>
				)}
			</>
		</FormCard>
	);
};
