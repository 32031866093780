export const getCookie = (cookieName) => {
	const cookieList = document.cookie.split(';');

	// if (cookieList[0] === '') return cookieList; !!!!!!!!!!!!!!! check cookies

	let newCookieList = [];

	cookieList.forEach((cookieStr) => {
		const cookie = cookieStr.split('=');
		newCookieList.push({ name: cookie[0], value: cookie[1] });
		// console.log('cookie', cookie.split('='));
	});

	const result = newCookieList.find(
		(cookie) => cookieName.trim() === cookie.name.trim()
	);

	return result;
};
