import s from './userDocuments.module.css';

export const UserDocuments = () => {
	return (
		<div className={s.container}>
			<h3 className={s.title}>Документы</h3>

			<table className={s.table}>
				<thead>
					<tr className={s.row}>
						<th>Наименование</th>
						<th>Сумма</th>
						<th>Дата</th>
						<th>Приобритение</th>
						<th>Статус</th>
						<th>Время заполнения</th>
						<th></th>
					</tr>
				</thead>

				<tbody>
					<Row
						docName={'Заявление о расторжении брака'}
						sum={1600}
						datePay={'11.11.1111'}
						purchase={'Покупка'}
						status={'Действующий'}
						dateCompletion={'11.11.1111'}
					/>
					<Row
						docName={'Заявление о расторжении брака'}
						sum={1600}
						datePay={'11.11.1111'}
						purchase={'Покупка'}
						status={'Действующий'}
						dateCompletion={'11.11.1111'}
					/>
					<Row
						docName={'Заявление о расторжении брака'}
						sum={1600}
						datePay={'11.11.1111'}
						purchase={'Покупка'}
						status={'Действующий'}
						dateCompletion={'11.11.1111'}
					/>
				</tbody>
			</table>
		</div>
	);
};

function Row({ docName, sum, datePay, purchase, status, dateCompletion }) {
	return (
		<tr className={s.row}>
			<td>{docName}</td>
			<td>{sum} р</td>
			<td>{datePay}</td>
			<td>{purchase}</td>
			<td>{status}</td>
			<td>{dateCompletion}</td>
			<td>
				<button className={s.btn}>Просмотреть</button>
			</td>
		</tr>
	);
}
