import { SaveAs } from './saveAs';
import { useDispatch, useSelector } from 'react-redux';
import {
	setDescription,
	setFieldList,
	setId,
	setName,
	setPath,
	setPrice,
	setStateDocument,
} from '../../../../store/features/document/documentSlice';
import { sendDocument } from '../../../../store/features/document/thunks';
import { selectServices } from '../../../../store/selectors';
import { useContext } from 'react';
import { EditorContext } from '../content/contentContainer';
import { decoratorList } from '../textEditor/config/decoratorList/decoratorList';
import { EditorState } from 'draft-js';

export const SaveAsContainer = ({
	setOpenModalSaveAs,
	checkingForUseAllFieldsInDocument,
	setOpenRequestResultWindow,
	setRequestResult,
}) => {
	const { setEditorState, serviceList } = useContext(EditorContext);

	const dispatch = useDispatch();
	const { dirList } = useSelector(selectServices);

	const saveDocument = (nameDoc, path, description, price) => {
		dispatch(setId(null));
		dispatch(setName(nameDoc));
		dispatch(setPath(path));
		dispatch(setDescription(description));
		dispatch(setPrice(price));
		dispatch(setFieldList(checkingForUseAllFieldsInDocument()));
		setOpenModalSaveAs(false);
		dispatch(sendDocument()).then((res) => {
			// console.log(res);
			setOpenRequestResultWindow(true);

			if (res.payload.statusText === 'OK') {
				setRequestResult('Success!');
				dispatch(setStateDocument());
				setEditorState(EditorState.createEmpty(decoratorList));
				return;
			}
			// setEditorState(EditorState.createEmpty(decoratorList));
			setRequestResult(res.payload.message);
			// setOpenRequestResultWindow(true);
		});
	};

	// console.log('rer');
	return (
		<SaveAs
			dirList={dirList}
			serviceList={serviceList}
			saveDocument={saveDocument}
		/>
	);
};
