import { createAsyncThunk } from '@reduxjs/toolkit';
import { templateApi, userDocApi } from '../../../api/api';
import { setServiceList } from '../services/servicesSlice';
import { setStateDocument } from './documentSlice';

export const sendDocument = createAsyncThunk(
	'document/sendDocument',
	async (_, { getState, dispatch, rejectWithValue }) => {
		const document = getState().document;

		try {
			if (document.name) {
				const res = await templateApi.saveTemplate(document);

				// console.log(res);
				// dispatch(setStateDocument());
				dispatch(setServiceList(res.data));
				return res;
			}

			return false;
		} catch (error) {
			// dispatch(setStateDocument());
			return rejectWithValue(error);
		}
	}
);

export const createUserDocument = createAsyncThunk(
	'document/createUserDocument',
	async (_, { getState }) => {
		const userId = getState().user.id;
		const doc = getState().document;

		try {
			const res = await userDocApi.createUserDoc({
				userId,
				doc,
			});
			return res;
		} catch (error) {
			return error;
		}
	}
);

export const updateUserDocument = createAsyncThunk(
	'document/updateUserDocument',
	async (_, { getState }) => {
		// const userId = getState().user.id;
		const doc = getState().document;

		try {
			const res = await userDocApi.updateUserDoc({
				userId: doc.userid,
				doc,
			});
			return res;
		} catch (error) {
			return error;
		}
	}
);
