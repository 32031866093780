import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import {
	setEditorContent,
	setId,
	setStateDocument,
} from '../../../../store/features/document/documentSlice';
import { OpenFile } from './openFile';
// import { selectServices } from '../../../../store/selectors';
import { setFieldKey } from '../../../../store/features/services/servicesSlice';
import { decoratorList } from '../textEditor/config/decoratorList/decoratorList';
import { EditorContext } from '../content/contentContainer';
import { creatorDocAndStateFromTemplate } from '../../../../core/utils/functions/creatorDocAndStateFromTemplate';

export const OpenFileContainer = ({ setOpenModalOpenFile }) => {
	const { serviceList, setEditorState } = useContext(EditorContext);

	const dispatch = useDispatch();
	const [fileDocValue, setFileDocValue] = useState('');

	const openFile = () => {
		const currentFile = serviceList.find(
			(file) => `${file.name} (${file.path})` === fileDocValue
		);

		if (!currentFile) {
			console.log('File not found'); // Message File not found
			return;
		}

		const newDocAndState = creatorDocAndStateFromTemplate(currentFile);

		dispatch(setStateDocument(newDocAndState.doc));
		setEditorState(newDocAndState.state);
		setOpenModalOpenFile(false);
	};

	// function setEntityKeys(editorState, file) {
	// 	const contentState = editorState.getCurrentContent();
	// 	const blockList = contentState.getBlockMap();

	// 	blockList.forEach((block) => {
	// 		block.findEntityRanges((characterMetadata) => {
	// 			const keyCharacterMetadata = characterMetadata.getEntity();

	// 			if (keyCharacterMetadata !== null) {
	// 				const currentEntity = contentState.getEntity(keyCharacterMetadata);
	// 				const currentEntityKey = currentEntity.getData().key;
	// 				const currentEntityType = currentEntity.type;

	// 				if (
	// 					keyCharacterMetadata !== currentEntityKey
	// 					// &&						currentEntityType === 'FIELD'
	// 				) {
	// 					for (let i = 0; i < file.fieldList.length; i++) {
	// 						if (file.fieldList[i].key === currentEntityKey) {
	// 							dispatch(
	// 								setFieldKey({
	// 									fileId: file.id,
	// 									field: file.fieldList[i],
	// 									newKey: keyCharacterMetadata,
	// 								})
	// 							);

	// 							break;
	// 						}
	// 					}

	// 					contentState.mergeEntityData(keyCharacterMetadata, {
	// 						key: keyCharacterMetadata,
	// 					});
	// 				}
	// 			}
	// 		});
	// 	});

	// 	return editorState;
	// }

	// console.log('rerender');
	return (
		<OpenFile
			serviceList={serviceList}
			fileDocValue={fileDocValue}
			setFileDocValue={setFileDocValue}
			openFile={openFile}
		/>
	);
};
