import { useEffect, useState } from 'react';
import s from './verifyPage.module.css';
import { useHref } from 'react-router-dom';
import { userDocApi, verifyApi } from '../../../api/api';

export const VerifyPage = () => {
	const href = useHref();
	const [result, setResult] = useState('');

	useEffect(() => {
		verifyApi
			.isVerify(href)
			.then((res) => {
				console.log(res);
				setResult(res.data.message);
			})
			.catch((err) => setResult(err.response.data.error));
	}, []);

	return (
		<>
			<h1>verifyPage</h1>
			<div>{result}</div>
		</>
	);
};
