import { useEffect } from 'react';
import { useNavigate, Route, Routes, useHref } from 'react-router-dom';
import { useAuth } from '../../../../../core/hooks/useAuth';
import s from './userOfficeMobile.module.css';
import { MenuItemMobile } from '../../../../common/menuItem/menuItemMobile/menuItemMobile';
import { MainMenu } from '../../../../common/mainMenu/mainMenu';
import { Section } from '../../../../common/section/section';
import { ReactComponent as Man } from '../../../../../img/icon/man.svg';
import { ReactComponent as MyPay } from '../../../../../img/icon/myPay.svg';
import { ReactComponent as BookmarkIcon } from '../../../../../img/bookmarkIcon.svg';
import { ReactComponent as Subscription } from '../../../../../img/icon/subscription.svg';
import { ReactComponent as Settings } from '../../../../../img/icon/setting.svg';
import { ReactComponent as Support } from '../../../../../img/icon/support.svg';
import { ReactComponent as Arrow } from '../../../../../img/arrow2.svg';
import { UserDataMobile } from '../userData/userDataMobile/userDataMobile';
import { UserInfo } from '../userData/userInfo/userInfo';
import { PayData } from '../userData/payData/payData';
import { PurchaseHistory } from '../userData/purchaseHistory/purchaseHistory';
import { ReplenishmentHistory } from '../userData/replenishmentHistory/replenishmentHistory';
import { PurchasesMobile } from '../purchases/purchasesMobile/purchasesMobile';
import { Favorites } from '../favorites/favorites';
import { Subscriptions } from '../subscriptions/subscriptions';
import { SettingsContent } from '../settingsContent/settingsContent';
import { SupportContent } from '../supportContent/supportContent';

export const UserOfficeMobilePage = () => {
	const navigate = useNavigate();
	const { isAuth } = useAuth();

	const href = useHref();

	const handlerNavigation = (path, state) => {
		// console.log(state);
		navigate(path, state);
	};

	useEffect(() => {
		// console.log(isAuth);
		if (!isAuth) {
			navigate('/');
		}
	}, [isAuth]);

	return (
		<Section className={s.section}>
			{href === '/userOffice' ? (
				<h3 className={s.title}>Личный кабинет</h3>
			) : (
				<button className={s.btnBack} onClick={() => navigate(-1)}>
					<Arrow /> Вернуться
				</button>
			)}

			{href === '/userOffice' && (
				<MainMenu
					classes={s.categoryList}
					list={
						<>
							<MenuItemMobile
								icon={<Man />}
								name={'Личные данные'}
								clickFunction={() => handlerNavigation('userData')}
							/>

							<MenuItemMobile
								icon={<MyPay />}
								name={'Мои покупки'}
								clickFunction={() => handlerNavigation('purchases')}
							/>

							<MenuItemMobile
								icon={<BookmarkIcon />}
								name={'Избранное'}
								clickFunction={() => handlerNavigation('favorites')}
							/>

							<MenuItemMobile
								icon={<Subscription />}
								name={'Подписки'}
								clickFunction={() => handlerNavigation('subscriptions')}
							/>

							<MenuItemMobile
								icon={<Settings />}
								name={'Настройки'}
								clickFunction={() => handlerNavigation('settings')}
							/>

							<MenuItemMobile
								icon={<Support />}
								name={'Поддержка'}
								clickFunction={() => handlerNavigation('support')}
							/>
						</>
					}
				/>
			)}

			<Routes>
				<Route
					path='userData'
					element={<UserDataMobile handlerNavigation={handlerNavigation} />}
				/>
				<Route path='userData/userInfo' element={<UserInfo />} />
				<Route path='userData/paymentData' element={<PayData />} />
				<Route path='userData/purchaseHistory' element={<PurchaseHistory />} />
				<Route
					path='userData/replenishmentHistory'
					element={<ReplenishmentHistory />}
				/>

				<Route
					path='purchases/*'
					element={
						<PurchasesMobile
							href={href}
							handlerNavigation={handlerNavigation}
						/>
					}
				/>
				<Route path='favorites' element={<Favorites />} />
				<Route path='subscriptions' element={<Subscriptions />} />
				<Route path='settings' element={<SettingsContent />} />
				<Route path='support' element={<SupportContent />} />
			</Routes>
		</Section>
	);
};
