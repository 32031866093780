import { Button } from '../../../../common/button/button';
import { Checkbox } from '../../../../common/checkbox/checkbox';
import s from './settingsContent.module.css';
import { ReactComponent as ArrowFlag } from '../../../../../img/linkArrowFlag.svg';
import { Input } from '../../../../common/input/input';

export const SettingsContent = () => {
	return (
		<div className={s.container}>
			<h3 className={s.title}>Настройки</h3>

			<form className={s.form} onSubmit={() => {}}>
				<Checkbox text={'Получать уведомления по e-mail'} />
				<Checkbox text={'Автоматически отправлять заявления после оплаты'} />
				<Checkbox text={'Узнавать о новостях и акциях по e-mail'} />

				<Button
					type={'submit'}
					text={
						<>
							{'Сохранить'}
							<ArrowFlag />
						</>
					}
				/>
			</form>

			<h3 className={s.title}>Изменить пароль</h3>

			<form className={s.form} onSubmit={() => {}}>
				<Input
					classes={s.input}
					// inputValue={}
					// changeFunction={}
					// clickFunction={}
					// keyDownEnterFunction={}
					placeholder={'Текущий пароль'}
				/>{' '}
				<Input
					classes={s.input}
					// inputValue={}
					// changeFunction={}
					// clickFunction={}
					// keyDownEnterFunction={}
					placeholder={'Новый пароль'}
				/>{' '}
				<Input
					classes={s.input}
					// inputValue={}
					// changeFunction={}
					// clickFunction={}
					// keyDownEnterFunction={}
					placeholder={'Повторите пароль*'}
				/>
				<Button
					type={'submit'}
					text={
						<>
							{'Сохранить'}
							<ArrowFlag />
						</>
					}
				/>
			</form>
		</div>
	);
};
