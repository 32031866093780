import React, { useCallback } from 'react';
import s from './field.module.css';
import { ButtonField } from './buttonField/buttonField';

export const Field = ({ field, addField, removeField }) => {
	// console.log(field);

	const add = () => {
		addField(field.name, field.key);
	};

	return (
		<div className={s.container}>
			<span className={s.fieldName}>{field.name}</span>

			<div className={s.btnContainer}>
				<ButtonField text={'+'} clickFunction={add} />

				<ButtonField text={<>&#215;</>} clickFunction={removeField} />
			</div>
		</div>
	);
};
