import { useEffect, useRef, useState } from 'react';
import s from './searchBlock.module.css';
import axios from 'axios';
// import { InputWithBtn } from '../../../common/inputWithBtn/inputWithBtn';
import { MainSearch } from './mainSearch/mainSearch';

export const SearchBlock = () => {
	const autoScroll = useRef();

	const [inputMessage, setInputMessage] = useState('');
	const [messageList, setMessageList] = useState([]);
	const [isTyping, setIsTyping] = useState(false);
	const [conversationId, setConversationId] = useState(null);
	const [parentMessageId, setParentMessageId] = useState(null);
	const [isOpenChat, setIsOpenChat] = useState(false);
	const [scrollTop, setScrollTop] = useState(0);

	const sendMessage = async () => {
		const instance = axios.create({
			baseURL: 'http://95.79.30.69:1703',
			credentials: true,
			'access-control-allow-credentials': true,
		});

		setScrollTop(autoScroll.current.scrollHeight);
		// console.log(autoScroll);

		if (inputMessage.trim()) {
			setInputMessage('');

			try {
				const userMessage = { message: inputMessage, sender: 'user' };
				setMessageList((prevMessages) => [...prevMessages, userMessage]);

				setIsTyping(true);

				const requestData = {
					message: inputMessage,
				};

				if (conversationId) {
					requestData.conversationId = conversationId;
				}

				if (parentMessageId) {
					requestData.parentMessageId = parentMessageId;
				}

				const response = await instance.post('conversation', requestData);

				setIsTyping(false);

				if (response.data && response.data.response) {
					if (!conversationId && response.data.conversationId) {
						setConversationId(response.data.conversationId);
					}

					if (response.data.messageId) {
						setParentMessageId(response.data.messageId);
					}

					const chatbotMessage = {
						message: response.data.response,
						sender: 'chatbot',
					};

					setMessageList((prevMessages) => [...prevMessages, chatbotMessage]);

					setScrollTop(autoScroll.current.scrollHeight);
				}
			} catch (error) {
				console.error('Error sending message:', error);
				setIsTyping(false);

				if (error.response) {
					console.error('Server responded with an error:', error.response.data);
				} else if (error.request) {
					console.error(
						'No response was received from the server:',
						error.request
					);
				} else {
					console.error('Error setting up the request:', error.message);
				}
			}
		}
	};

	const handlerInputMessage = (e) => {
		setInputMessage(e.target.value);
	};

	useEffect(() => {
		autoScroll.current.scrollTop = scrollTop;
	}, [scrollTop]);

	return (
		<div
			style={
				isOpenChat
					? {
							padding: '1.25rem 0 0',
							backgroundColor: '#fff',
							boxShadow: '1px 1px .25rem 0px rgba(0, 0, 0, 0.35)',
					  }
					: {}
			}
			className={`${s.container}`}>
			<div
				ref={autoScroll}
				style={isOpenChat ? {} : { padding: '0', height: '0' }}
				className={s.messenger}>
				{messageList.map((message, i) => (
					<div
						style={
							isOpenChat
								? {}
								: {
										opacity: '0',
								  }
						}
						key={i}
						className={message.sender === 'user' ? s.user : s.chatbot}>
						{message.message}
					</div>
				))}

				{isTyping && (
					<div className='chatbot typing'>
						<span>Печатает...</span>
					</div>
				)}
			</div>

			<MainSearch
				inputMessage={inputMessage}
				inputChangeFunction={handlerInputMessage}
				sendMessage={sendMessage}
				isTyping={isTyping}
				isOpenChat={isOpenChat}
				setIsOpenChat={setIsOpenChat}
			/>

			<div
				style={isOpenChat ? {} : { display: 'none' }}
				className={s.cross}
				onClick={() => setIsOpenChat(false)}></div>
		</div>
	);
};

{
	/* <InputWithBtn
  classesContainer={s.input}
  // 	inputValue={inputMessage}
  // 	inputChangeFunction={(e) => {
  // 		setInputMessage(e.target.value);
  // 	}}
  // 	keyDownEnterFunction={sendMessage}
  // 	placeholder={
  // 		isTyping
  // 			? 'Дождитесь ответа консультанта'
  // 			: 'Задайте вопрос консультанту...'
  // 	}
  // 	btnText='Отправить'
  // 	btnClickFunction={sendMessage}
  disabled={isTyping ? true : false}
/> */
}
