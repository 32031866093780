import s from './serviceChangeHistory.module.css';

export const ServiceChangeHistory = ({ listOfChange }) => {
	return (
		<div className={s.historyBlock}>
			<div className={s.historyBlockHeadRow}>
				<h6>Дата</h6>
				<h6>Статус</h6>
			</div>

			{listOfChange.map((el, i) => (
				<HistoryRow key={i} date={el.date} status={el.status} />
			))}
		</div>
	);
};

function HistoryRow({ date, status }) {
	return (
		<div className={s.historyRow}>
			<p>{date}</p>
			<p>{status}</p>
		</div>
	);
}
