import { useNavigate, Route, Routes, useHref } from 'react-router-dom';
import s from './maintenance.module.css';
import { Input } from '../../../../common/input/input';
// import { Button } from '../../../common/button/button';
import { ReactComponent as SearchIcon } from '../../../../../img/searchIcon.svg';
import { ReactComponent as Arrow } from '../../../../../img/arrow2.svg';
import { useRef, useState } from 'react';
import { useOutsideClick } from '../../../../../core/hooks/useOutsideClick';
import { Appeal } from './appeal/appeal';
import { DropdownMenu } from '../../../../common/dropdownMenu/dropdownMenu';
// import { User } from './user/user';

export const Maintenance = () => {
	const navigate = useNavigate();
	const href = useHref();

	const handlerUserNavigate = (userId) => {
		navigate(`${userId}`);
	};

	return (
		<div className={s.container}>
			<div className={s.headRow}>
				<div>
					{href === '/adminOffice/support' ? (
						<h3 className={s.title}>Поддержка</h3>
					) : (
						<button className={s.btnBack} onClick={() => navigate(-1)}>
							<Arrow /> Вернуться
						</button>
					)}

					<div className={s.search}>
						<Input
							classes={s.input}
							// inputValue={}
							// changeFunction={}
							// clickFunction={}
							// keyDownEnterFunction={}
							placeholder={'Поиск'}
						/>

						<SearchIcon className={s.searchIcon} />
					</div>
				</div>

				<div>
					<p>Сортировать: по дате</p>

					<p>Вся история</p>
				</div>
			</div>

			<div className={s.content}>
				{href === '/adminOffice/support' && (
					<table className={s.table}>
						<thead>
							<tr className={s.row}>
								<th>ID</th>
								<th>Дата создания</th>
								<th>ФИО</th>
								<th>Тема</th>
								<th>Вопрос</th>
								<th>Статус</th>
								<th>Действие</th>
							</tr>
						</thead>

						<tbody>
							<Row
								id={1234}
								date={'11.11.1111'}
								userName={'Иванов Иван Иванович'}
								them={'Заявление заполнено, но...'}
								question={'Сколько можно ждать?...'}
								status={'Ждет ответа'}
								handlerNavigate={handlerUserNavigate}
							/>
							<Row
								id={1234}
								date={'11.11.1111'}
								userName={'Иванов Иван Иванович'}
								them={'Заявление заполнено, но...'}
								question={'Сколько можно ждать?...'}
								status={'Ждет ответа'}
								handlerNavigate={handlerUserNavigate}
							/>
							<Row
								id={1234}
								date={'11.11.1111'}
								userName={'Иванов Иван Иванович'}
								them={'Заявление заполнено, но...'}
								question={'Сколько можно ждать?...'}
								status={'Ждет ответа'}
								handlerNavigate={handlerUserNavigate}
							/>
						</tbody>
					</table>
				)}

				<Routes>{<Route path=':appealId' element={<Appeal />} />}</Routes>
			</div>
		</div>
	);
};

function Row({ id, userName, date, them, question, status, handlerNavigate }) {
	return (
		<tr className={s.row}>
			<td>{id}</td>
			<td>{date}</td>
			<td>{userName}</td>
			<td>{them} </td>
			<td>{question}</td>
			<td>{status}</td>
			<td>
				<DropdownMenu
					list={[
						{
							name: 'Ответить',
							clickFunction: () => handlerNavigate(id),
						},
						{ name: 'Заблокировать' },
						{ name: 'Удалить' },
					]}
				/>
			</td>
		</tr>
	);
}
