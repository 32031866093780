import { useState } from 'react';
import s from './payData.module.css';
import { ReactComponent as Bank } from '../../../../../../img/icon/bank.svg';
import { ReactComponent as Card } from '../../../../../../img/icon/card.svg';
import { ReactComponent as SberPay } from '../../../../../../img/sberPay.svg';
import { ReactComponent as TinkPay } from '../../../../../../img/tinkPay.svg';
import { ReactComponent as AlfaPay } from '../../../../../../img/alfaPay.svg';
import { ReactComponent as SBP } from '../../../../../../img/sbp.svg';
import { ReactComponent as Money } from '../../../../../../img/icon/money.svg';

import { InputWithBtn } from '../../../../../common/inputWithBtn/inputWithBtn';
import { PaymentOnAccount } from './paymentOnAccount/paymentOnAccount';
import { PaymentByCard } from './paymentByCard/paymentByCard';
import { PaymentByPay } from './paymentByPay/paymentByPay';
import { PaymentBySBP } from './paymentBySBP/paymentBySBP';
import { OnlineWallet } from './onlineWallet/onlineWallet';

export const PayData = () => {
	const PAYMENT_ON_ACCOUNT = 'PAYMENT_ON_ACCOUNT';
	const PAYMENT_BY_CARD = 'PAYMENT_BY_CARD';
	const PAYMENT_BY_PAY = 'PAYMENT_BY_PAY';
	const PAYMENT_BY_SBP = 'PAYMENT_BY_SBP';
	const ONLINE_WALLET = 'ONLINE_WALLET';

	const [payMethod, setPayMethod] = useState(PAYMENT_ON_ACCOUNT);

	const handlerPayMethod = (payMethod) => {
		setPayMethod(payMethod);
	};

	return (
		<div className={s.container}>
			<h3 className={s.title}>Платежные данные</h3>

			<div className={s.payMethod}>
				<PaymentMethodCard
					payMethod={payMethod === PAYMENT_ON_ACCOUNT}
					clickFunction={() => handlerPayMethod(PAYMENT_ON_ACCOUNT)}
					icon={<Bank />}
					text={'По счету (для Юр.лиц)'}
				/>

				<PaymentMethodCard
					payMethod={payMethod === PAYMENT_BY_CARD}
					clickFunction={() => handlerPayMethod(PAYMENT_BY_CARD)}
					icon={<Card />}
					text={'Оплата по карте'}
				/>

				<PaymentMethodCard
					payMethod={payMethod === PAYMENT_BY_PAY}
					clickFunction={() => handlerPayMethod(PAYMENT_BY_PAY)}
					icon={
						<div>
							<SberPay />
							<TinkPay />
							<AlfaPay />
						</div>
					}
					text={'PAY'}
				/>

				<PaymentMethodCard
					payMethod={payMethod === PAYMENT_BY_SBP}
					clickFunction={() => handlerPayMethod(PAYMENT_BY_SBP)}
					icon={<SBP />}
					text={'QR код'}
				/>

				<PaymentMethodCard
					payMethod={payMethod === ONLINE_WALLET}
					clickFunction={() => handlerPayMethod(ONLINE_WALLET)}
					icon={<Money />}
					text={'Электронный кошелек'}
				/>
			</div>

			<div className={s.payContent}>
				{payMethod === PAYMENT_ON_ACCOUNT && <PaymentOnAccount />}

				{payMethod === PAYMENT_BY_CARD && <PaymentByCard />}

				{payMethod === PAYMENT_BY_PAY && <PaymentByPay />}

				{payMethod === PAYMENT_BY_SBP && <PaymentBySBP />}

				{payMethod === ONLINE_WALLET && <OnlineWallet />}

				<div className={s.order}>
					<InputWithBtn
						classesContainer={s.inputWithBtnWrp}
						classesInput={s.inputWithBtn}
						classesBtn={s.inputWithBtn}
						btnText={'Пополнить'}
						placeholder={'Сумма'}
					/>

					<span>Банк может взымать дополнительную помиссию</span>

					<p>К оплате: 1 648 рублей</p>
				</div>
			</div>
		</div>
	);
};

function PaymentMethodCard({ icon, text, payMethod, clickFunction }) {
	return (
		<button
			className={`${s.paymentMethodCard} ${
				payMethod ? s.paymentMethodCardActive : ''
			}`}
			onClick={clickFunction}>
			{icon}

			<p className={s.cardName}>{text}</p>
		</button>
	);
}
