import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { decoratorList } from '../../../components/pages/editorPage/textEditor/config/decoratorList/decoratorList';

// We use the function creatorDocAndStateFromTemplate, because when creating a NEW EditorState, the editor creates NEW Keys for ALL ENTITIES.

export const creatorDocAndStateFromTemplate = (template) => {
	const newEditorState = EditorState.createWithContent(
		convertFromRaw(template.editorContent),
		decoratorList
	);

	const contentState = newEditorState.getCurrentContent();
	const blockList = contentState.getBlockMap();

	const newFieldList = [];

	blockList.forEach((block) => {
		block.findEntityRanges((characterMetadata) => {
			const entityKey = characterMetadata.getEntity();

			if (entityKey !== null) {
				const currentEntity = contentState.getEntity(entityKey);
				const currentEntityKey = currentEntity.getData().key;

				if (entityKey !== currentEntityKey) {
					contentState.mergeEntityData(entityKey, { key: entityKey });

					// newFieldList.push(contentState.getEntity(entityKey).data);

					// console.log(currentEntity.data);
					if (currentEntity.data.type !== 'image') {
						newFieldList.push(contentState.getEntity(entityKey).data);
					}
				}
			}
		});
	});

	return {
		doc: {
			...template,
			editorContent: convertToRaw(newEditorState.getCurrentContent()),
			fieldList: newFieldList,
		},
		state: newEditorState,
	};
};
