import s from './cardWithTable.module.css';

export const CardWithTable = ({ title, list }) => {
	return (
		<div className={s.container}>
			<div className={s.titleBlock}>
				<div className={s.title}>{title}</div>

				<div className={s.history}>Вся история</div>
			</div>

			<div className={s.table}>
				<div className={s.tableHead}>
					<div className={s.cell}>Наименование</div>
					<div className={s.cell}>Сумма</div>
					<div className={s.cell}>Дата</div>
				</div>

				{/* <div className={s.list}> */}
				{list?.map((el, i) => (
					<Row key={i} el={el} i={i} />
				))}
				{/* </div> */}
			</div>
		</div>
	);
};

function Row({ el, i }) {
	return (
		<div className={s.row}>
			<div className={s.cell}>
				{i + 1}. {el.name}
			</div>
			<div className={s.cell}>{el.price} руб</div>
			<div className={s.cell}>{el.date}</div>
		</div>
	);
}
