import s from './userDataMobile.module.css';
import { ReactComponent as ArrowFlag } from '../../../../../../img/linkArrowFlag.svg';

export const UserDataMobile = ({ handlerNavigation }) => {
	return (
		<div className={s.container}>
			<div className={s.subscription}>
				<div className={s.card}>
					<p>Баланс</p>
					<p>1280 рублей</p>
					<p>Пополнить</p>
				</div>

				<div className={s.card}>
					<p>Подписка</p>
					<p>Осталось 12 дней</p>
					<p>Продлить</p>
				</div>
			</div>

			<div className={s.list}>
				<ItemMenu
					text={'Личные информация'}
					clickFunction={() => {
						handlerNavigation('userData/userInfo');
					}}
				/>
				<ItemMenu
					text={'Платежные данные'}
					clickFunction={() => {
						handlerNavigation('userData/paymentData');
					}}
				/>
				<ItemMenu
					text={'Покупки'}
					clickFunction={() => {
						handlerNavigation('userData/purchaseHistory');
					}}
				/>
				<ItemMenu
					text={'Пополнения'}
					clickFunction={() => {
						handlerNavigation('userData/replenishmentHistory');
					}}
				/>
			</div>
		</div>
	);
};

function ItemMenu({ text, clickFunction }) {
	return (
		<button className={s.itemMenu} onClick={clickFunction}>
			{text}
			<ArrowFlag />
		</button>
	);
}
