import React, { useContext } from 'react';
import s from './closeDoc.module.css';
import { Button } from '../../../../common/button/button';
import { FormCard } from '../../../../common/formCard/formCard';
import { EditorContext } from '../contentContainer';

export const CloseDoc = ({ setOpenModalCloseDoc }) => {
	const { closeDocument, handlerSaveDocument } = useContext(EditorContext);

	const closeDoc = () => {
		closeDocument();
		setOpenModalCloseDoc(false);
	};

	const saveDoc = async () => {
		await handlerSaveDocument();
		setOpenModalCloseDoc(false);
		closeDocument();
	};

	return (
		<FormCard title={'Закрыть без изменений?'}>
			<div className={s.container}>
				<Button text={'Сохранить'} clickFunction={saveDoc} />
				<Button text={'Закрыть'} clickFunction={closeDoc} />
			</div>
		</FormCard>
	);
};
