import { Button } from '../../../../../common/button/button';
import s from './serviceStatus.module.css';
import { ReactComponent as ArrowFlag } from '../../../../../../img/linkArrowFlag.svg';

export const ServiceStatus = ({ classes }) => {
	return (
		<div className={`${s.statusBlock} ${classes}`}>
			<div>
				<p>Отправлено</p>
				<p>Ожидает решение суда</p>
			</div>

			<div>
				<Button
					classes={s.btn}
					text={
						<>
							Посмотреть документ <ArrowFlag />
						</>
					}
				/>
				<p>Скачать в PDF</p>
			</div>
		</div>
	);
};
