import { FlagLine } from '../flagLine/flagLine';
import s from './footer.module.css';
import { ReactComponent as Phone } from '../../../img/icon/socNetworks/phone.svg';
import { ReactComponent as Telegram } from '../../../img/icon/socNetworks/telegram.svg';
import { ReactComponent as WhatsApp } from '../../../img/icon/socNetworks/whatsApp.svg';
import { ReactComponent as Mail } from '../../../img/icon/socNetworks/mail.svg';
import { Section } from '../section/section';
import { Logo } from '../logo/logo';
import { Link } from '../link/link';

export const Footer = () => {
	return (
		<footer>
			<FlagLine />

			<Section>
				<div className={s.had}>
					<div></div>

					<Logo />

					<div>
						<Phone />
						<Telegram />
						<WhatsApp />
						<Mail />
					</div>
				</div>

				<div className={s.links}>
					<Link>Каталог</Link>
					<Link>Как это работает?</Link>
					<Link>Подать в суд</Link>
					<Link>Определить подсудность</Link>
					<Link>Найти юриста</Link>
					<Link>Правовая информация</Link>
				</div>

				<p>2023 Все права защищены</p>
			</Section>
		</footer>
	);
};
