import s from './modalWindow.module.css';

export const ModalWindow = (props) => {
	// console.log(clickFunction);
	return (
		<div
			className={s.container}
			// onClick={clickFunction}
		>
			<div
				className={`${s.window} ${props.classes}`}
				onClick={(e) => e.stopPropagation()}>
				{props.children}

				{props.clickFunction !== undefined && (
					<div className={` ${s.cross}`} onClick={props.clickFunction}></div>
				)}
			</div>
		</div>
	);
};
