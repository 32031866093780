import s from './editorControlItem.module.css';

export const EditorControlItem = ({ isActive, onToggle, children, style }) => {
	const onToggleHandler = (e) => {
		e.preventDefault();
		onToggle(style);
	};

	const currentClassName = `${s.RichEditorStyleButton} ${
		isActive ? s.RichEditorActiveButton : ''
	}`;

	return (
		<div className={currentClassName} onMouseDown={onToggleHandler}>
			{children}
		</div>
	);
};
