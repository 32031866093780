import React, { useContext } from 'react';
import { EditorState, Modifier, convertFromRaw, convertToRaw } from 'draft-js';
import { useDispatch, useSelector } from 'react-redux';
import { removeFieldFromList } from '../../../../../store/features/document/documentSlice';
import { selectDocument } from '../../../../../store/selectors';
import { Field } from './field';
import { decoratorList } from '../../textEditor/config/decoratorList/decoratorList';
import { EditorContext } from '../../content/contentContainer';
import { readdEntityToEditorState } from '../../../../../core/utils/functions/readdEntityToEditorState';

export const FieldContainer = ({ field }) => {
	const { editorState, handlerEditorState, dispatchCurrentContent } =
		useContext(EditorContext);
	const dispatch = useDispatch();
	const fieldList = useSelector(selectDocument).fieldList;

	const addField = (name, key) => {
		const newEditorState = readdEntityToEditorState(editorState, name, key);
		handlerEditorState(newEditorState);
		dispatchCurrentContent(newEditorState);
	};

	const removeField = () => {
		const newEditorState = removeFieldFromEditorState(field);

		handlerEditorState(newEditorState);
		dispatchCurrentContent(newEditorState);

		dispatch(removeFieldFromList(field));
	};

	function removeFieldFromEditorState(field) {
		const content = convertToRaw(editorState.getCurrentContent());
		const contentBlocks = content.blocks;

		contentBlocks.forEach((block) => {
			const currentField = new RegExp(
				`#${field.name.replace(/\(|\)/g, '\\$&')}#`,
				'gi'
			);

			const result = block.text.replace(currentField, '');
			block.text = result;
		});

		// Remove Entity from EntityMap
		const remoteFieldIndex = fieldList.findIndex((el) => el.key === field.key);
		const { [`${remoteFieldIndex}`]: remoteEntity, ...newEntityMap } =
			content.entityMap;
		content.entityMap = newEntityMap;

		const newEditorState = EditorState.createWithContent(
			convertFromRaw(content),
			decoratorList
		);
		return newEditorState;
	}

	// console.log('fieldContainer');
	return <Field field={field} addField={addField} removeField={removeField} />;
};
