import s from './mainSearch.module.css';
import { Button } from '../../../../common/button/button';
import { Input } from '../../../../common/input/input';
import { ReactComponent as SearchIcon } from '../../../../../img/searchIcon.svg';
import { ReactComponent as FilterIcon } from '../../../../../img/filterIcon.svg';
import { WindowWidthContext } from '../../../../app/app';
import { useContext } from 'react';

export const MainSearch = ({
	inputMessage,
	inputChangeFunction,
	sendMessage,
	isTyping,
	isOpenChat,
	setIsOpenChat,
}) => {
	const { windowWidth, windowWidthPhone, windowWidthTablet } =
		useContext(WindowWidthContext);

	return (
		<div className={s.container}>
			<div
				style={
					isOpenChat && windowWidth > windowWidthPhone
						? { transform: 'translateY(3.9rem)' }
						: {}
				}
				className={s.row}>
				<div className={s.search}>
					<Input
						classes={s.input}
						inputValue={inputMessage}
						changeFunction={inputChangeFunction}
						clickFunction={() => setIsOpenChat(true)}
						keyDownEnterFunction={sendMessage}
						placeholder={
							isTyping ? 'Дождитесь ответа консультанта' : 'Что вас интересует?'
						}
						disabled={isTyping ? true : false}
					/>

					<FilterIcon className={s.filterIcon} />
					<div className={s.line}></div>
					<SearchIcon className={s.searchIcon} onClick={sendMessage} />
				</div>
			</div>

			{windowWidth > windowWidthPhone ? (
				<div
					style={isOpenChat ? { transform: 'translateY(-5.5rem)' } : {}}
					className={s.categories}>
					<Button text={'Расторжение брака'} />
					<Button text={'Заявление на возврат денежных средств'} />
					<Button text={'Пособие по безработице'} />
					{windowWidth > windowWidthTablet ? (
						<Button text={'Возражение на судебный приказ'} />
					) : (
						<></>
					)}
				</div>
			) : (
				<></>
			)}
		</div>
	);
};
