import { useNow } from '../../../core/hooks/useNow';

export const Timer = ({ text, timerTime, startAt, setStartAt }) => {
	const now = useNow(1000, startAt);

	const msToStop = timerTime - (now - startAt);

	// console.log(msToStop);

	if (Math.floor(msToStop / 1000) < 0) {
		setStartAt(false);
	}

	return <div>{`${text}: ${Math.floor(msToStop / 1000)}`}</div>;
};
