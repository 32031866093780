import { EditorState, Modifier, convertToRaw } from 'draft-js';

export const readdEntityToEditorState = (
	editorState,
	entityName,
	entityKey
) => {
	const currentContent = editorState.getCurrentContent();
	const selection = editorState.getSelection();

	const entity = currentContent.getEntity(entityKey);
	const entityType = entity.getData().type;

	let newContent = Modifier.applyEntity(currentContent, selection, entityKey);

	newContent = Modifier.replaceText(
		currentContent,
		selection,
		entityType === 'signature' ? ' ' : `#${entityName}#`,
		null,
		entityKey
	);

	const newEditorState = EditorState.push(
		editorState,
		newContent,
		'insert-characters'
	);
	return newEditorState;
};
