import s from './flagLine.module.css';

export const FlagLine = () => {
	return (
		<div className={s.flag}>
			<div className={s.line}></div>
			<div className={`${s.line} ${s.blue}`}></div>
			<div className={`${s.line} ${s.red}`}></div>
		</div>
	);
};
