import s from './paymentBySBP.module.css';
import { ReactComponent as Arrow } from '../../../../../../../img/arrow.svg';

export const PaymentBySBP = () => {
	return (
		<div className={s.container}>
			<div>
				<Arrow />
				<p>Введите сумму и нажмите “Пополнить”, чтобы получить QR - код</p>
			</div>
		</div>
	);
};
