import { memo } from 'react';
import s from './button.module.css';

export const Button = memo(
	({ type, classes, text, clickFunction, disabled }) => {
		return (
			<button
				className={`${s.btn} ${classes}`}
				type={type}
				onClick={clickFunction}
				disabled={disabled}>
				{text}
			</button>
		);
	}
);
