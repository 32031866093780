import { createAsyncThunk } from '@reduxjs/toolkit';
import { setDirList, setServiceList } from './servicesSlice';
import { templateApi } from '../../../api/api';

export const getServiceList = createAsyncThunk(
	'services/getServiceList',
	async (_, { dispatch, rejectWithValue }) => {
		try {
			const res = await templateApi.getTemplateList();

			dispatch(setServiceList(res.data));
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getDirList = createAsyncThunk(
	'services/getDirList',
	async (_, { dispatch, rejectWithValue }) => {
		try {
			const res = await templateApi.getDirList();

			dispatch(setDirList(res.data));
		} catch (error) {
			// console.log(error);
			return rejectWithValue(error);
		}
	}
);
