import { createSlice } from '@reduxjs/toolkit';
import { EditorState, convertToRaw } from 'draft-js';
// import { sendDocument } from './thunks';

const initialState = {
	id: null,
	name: '',
	path: '',
	isMain: false,
	isPopular: false,
	description: '',
	price: '',
	editorContent: convertToRaw(EditorState.createEmpty().getCurrentContent()),
	fieldList: [],
};

export const documentSlice = createSlice({
	name: 'document',
	initialState,
	reducers: {
		setStateDocument: (state, action) =>
			(state = action.payload ? action.payload : initialState),

		setId: (state, action) => {
			state.id = action.payload;
		},

		setName: (state, action) => {
			state.name = action.payload;
		},

		setPath: (state, action) => {
			state.path = action.payload;
		},

		setIsMain: (state, action) => {
			state.isMain = action.payload;
		},

		setIsPopular: (state, action) => {
			state.isPopular = action.payload;
		},

		setDescription: (state, action) => {
			state.description = action.payload;
		},

		setPrice: (state, action) => {
			state.price = action.payload;
		},

		setEditorContent: (state, action) => {
			state.editorContent = action.payload;
		},

		setFieldList: (state, action) => {
			state.fieldList = action.payload;
			// console.log('setFieldList');
		},

		setFieldSrc: (state, action) => {
			state.fieldList.find((field) => field.key === action.payload.key).src =
				action.payload.src;
		},

		addFieldToTheList: (state, action) => {
			state.fieldList.push(action.payload);
		},

		removeFieldFromList: (state, action) => {
			const currentFieldIndex = state.fieldList.findIndex(
				(field) => field.key === action.payload.key
			);

			state.fieldList.splice(currentFieldIndex, 1);
		},

		setFieldValue: (state, action) => {
			state.fieldList.find((field) => field.key === action.payload.key).value =
				action.payload.value;

			const entityMapValues = Object.values(state.editorContent.entityMap);
			const currentEntity = entityMapValues.find(
				(entity) => entity.data.key === action.payload.key
			);
			currentEntity.data.value = action.payload.value;
		},

		setFieldDateFormatValue: (state, action) => {
			state.fieldList.find(
				(field) => field.key === action.payload.key
			).dateFormatValue = action.payload.value;
		},

		setValueForFieldWithTypeCourtOrCourtAddress: (state, action) => {
			// console.log(action.payload);
			const currentField = state.fieldList.find(
				(field) => field.type === action.payload.type
			);

			if (currentField !== undefined) {
				currentField.value = action.payload.value;
			}
		},
	},

	extraReducers: {
		// [sendDocument.pending]: () => console.log('pending'),
		// [sendDocument.rejected]: (err) => console.log('rej', err),
		// [sendDocument.fulfilled]: () => console.log('fulfilled'),
	},
});

export const {
	setStateDocument,
	setId,
	setName,
	setPath,
	setIsMain,
	setIsPopular,
	setDescription,
	setPrice,
	setEditorContent,
	setFieldList,
	setFieldSrc,
	addFieldToTheList,
	removeFieldFromList,
	setFieldValue,
	setFieldDateFormatValue,
	setValueForFieldWithTypeCourtOrCourtAddress,
} = documentSlice.actions;

export default documentSlice.reducer;
