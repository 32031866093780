import { FormCard } from '../../formCard/formCard';
import s from '../authentication.module.scss';

export const InformationWindow = ({ message }) => {
	return (
		<FormCard classes={s.form}>
			<h4>{message}</h4>
		</FormCard>
	);
};
