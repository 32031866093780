import { escapeRegExp } from 'lodash';

// export const insertValuesIntoDocument = (doc, signature = '') => {
// 	const content = doc.editorContent;
// 	const contentBlocks = content.blocks;

// 	doc.fieldList.forEach((field) => {
// 		if (field.type === 'signature') {
// 			if (signature) {
// 				const entityMapValues = Object.values(doc.editorContent.entityMap);

// 				const entitySignature = entityMapValues.find(
// 					(entity) => entity.data.key === field.key
// 				);
// 				entitySignature.data.src = signature;
// 			}
// 		} else {
// 			contentBlocks.forEach((block) => {
// 				const currentField = new RegExp(`#${escapeRegExp(field.name)}#`, 'g');

// 				const result = block.text.replace(currentField, field.value);
// 				block.text = result;
// 			});
// 		}
// 	});

// 	return doc;
// };

export const insertValuesIntoDocument = (doc) => {
	const content = doc.editorContent;
	const contentBlocks = content.blocks;

	doc.fieldList.forEach((field) => {
		if (field.type !== 'signature') {
			contentBlocks.forEach((block) => {
				const currentField = new RegExp(`#${escapeRegExp(field.name)}#`, 'g');

				const result = block.text.replace(currentField, field.value);
				block.text = result;
			});
		}
	});

	return doc;
};

// function insertValuesIntoDocument2(docAndState, signature) {
// 	const state = docAndState.doc.fieldList;
// 	// const entityMap = state.getCurrentContent().getEntityMap();

// 	docAndState.doc.fieldList.forEach((field) => {
// 		if (field.type === 'signature') {
// 			docAndState.state
// 				.getCurrentContent()
// 				.mergeEntityData(field.key, { src: signature });
// 		}
// 		docAndState.state
// 			.getCurrentContent()
// 			.mergeEntityData(field.key, { value: field.value });
// 	});
// 	// console.log(entityMap);
// 	// dispatch(setStateDocument(docAndState.doc));
// 	console.log(convertToRaw(docAndState.state.getCurrentContent()));

// 	dispatch(
// 		setEditorContent(convertToRaw(docAndState.state.getCurrentContent()))
// 	);
// }
