import { CardWithTable } from '../../../../../common/cardWithTable/cardWithTable';
import s from './purchaseHistory.module.css';

export const PurchaseHistory = () => {
	const list = [
		{
			name: 'Заявление о расторжении брака',
			price: 1650,
			date: '11.11.1111',
		},
		{
			name: 'Заявление о расторжении брака',
			price: 1650,
			date: '11.11.1111',
		},
		{
			name: 'Заявление о расторжении брака',
			price: 1650,
			date: '11.11.1111',
		},
		{
			name: 'Заявление о расторжении брака',
			price: 1650,
			date: '11.11.1111',
		},
		{
			name: 'Заявление о расторжении брака',
			price: 1650,
			date: '11.11.1111',
		},
	];
	return <CardWithTable title={'покупки'} list={list} />;
};
