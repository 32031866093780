import { memo } from 'react';
// import { CustomLink } from '../../../legacy/customLink/customLink';
import s from './navigation.module.css';
import { Link } from '../link/link';

export const Navigation = memo(({ linkList }) => {
	return (
		<nav className={s.container}>
			{linkList.map((link, i) => {
				return link.path ? (
					<Link key={i} className={s.link} path={link.path}>
						{link.text} /{' '}
					</Link>
				) : (
					<span key={i} className={s.link}>
						{link.text}
					</span>
				);
			})}
		</nav>
	);
});
