import { useContext } from 'react';
import { RichUtils } from 'draft-js';
import { BLOCK_TYPES } from '../../config/config';
// import { EditorContext } from '../../textEditor';
import { EditorControlItem } from '../editorControlItem/editorControlItem';
import { EditorContext } from '../../../content/contentContainer';

export const BlockTypeEditorControls = () => {
	const { editorState, setEditorState } = useContext(EditorContext);

	const selection = editorState.getSelection();
	const blockType = editorState
		.getCurrentContent()
		.getBlockForKey(selection.getStartKey())
		.getType();

	const toggleBlockType = (blockType) => {
		setEditorState(RichUtils.toggleBlockType(editorState, blockType));
	};

	return (
		<div className='RichEditor-controls'>
			{BLOCK_TYPES.map((type) => (
				<EditorControlItem
					key={type.label}
					style={type.style}
					isActive={type.style === blockType}
					onToggle={() => toggleBlockType(type.style)}>
					{type.label}
				</EditorControlItem>
			))}
		</div>
	);
};
