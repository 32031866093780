import { CardWithTable } from '../../../../../common/cardWithTable/cardWithTable';
import s from './replenishmentHistory.module.css';

export const ReplenishmentHistory = () => {
	const list = [
		{
			name: 'Пополнение баланса',
			price: 1650,
			date: '11.11.1111',
		},
		{
			name: 'Пополнение баланса',
			price: 1650,
			date: '11.11.1111',
		},
	];
	return <CardWithTable title={'Пополнения'} list={list} />;
};
