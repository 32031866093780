import { memo } from 'react';
import { Button } from '../button/button';
import { Input } from '../input/input';
import s from './inputWithBtn.module.css';

export const InputWithBtn = ({
	classesContainer,
	classesInput,
	classesBtn,
	inputValue,
	inputClickFunction,
	inputChangeFunction,
	keyDownEnterFunction,
	placeholder,
	btnType,
	btnText,
	btnClickFunction,
	disabled,
}) => {
	return (
		<div className={`${s.container} ${classesContainer}`}>
			<Input
				classes={`${classesInput} ${s.input}`}
				inputValue={inputValue}
				clickFunction={inputClickFunction}
				changeFunction={inputChangeFunction}
				keyDownEnterFunction={keyDownEnterFunction}
				placeholder={placeholder}
				disabled={disabled}
			/>

			<Button
				classes={`${s.btn} ${classesBtn}`}
				type={btnType}
				text={btnText}
				clickFunction={btnClickFunction}
				disabled={disabled}
			/>
		</div>
	);
};
