import { useContext, useEffect, useRef, useState } from 'react';
import s from './slider.module.css';
import img from '../../../../../img/image.png';
import { WindowWidthContext } from '../../../../app/app';
import { ServiceCard } from '../../../../common/serviceCard/serviceCard';

// export const Slider = ({ services }) => {   // !!!!!!mainCode;

export const Slider = () => {
	const sliderRef = useRef(null);
	const { windowWidth, windowWidthPhone, windowWidthTablet } =
		useContext(WindowWidthContext);
	// !!!!!! hardCode
	const services = [
		// !!!!!! hardCode
		{ id: 1, category: 1, service: 'doc.name' },
		{ id: 2, category: 2, service: 'doc.name' },
		{ id: 3, category: 3, service: 'doc.name' },
		{ id: 4, category: 4, service: 'doc.name' },
		{ id: 5, category: 5, service: 'doc.name' },
		// { id: 6, category: 6, service: 'doc.name' },
	];

	const VISIBLE_CARDS_COUNT = windowWidth > windowWidthTablet ? 5 : 3;

	const SCALE_SMALL_CARD = windowWidth > windowWidthPhone ? 0.9 : 1;
	const SCALE_MIDDLE_CARD = 1.02;
	const SCALE_CENTER_CARD = 1.36;

	const TRANSITION_DURATION = 800;

	const LEFT_SMALL_CARD = 'leftSmallCard';
	const LEFT_MIDDLE_CARD = 'leftMiddleCard';
	const CENTER_CARD = 'centerCard';
	const RIGHT_MIDDLE_CARD = 'rightMiddleCard';
	const RIGHT_SMALL_CARD = 'rightSmallCard';

	const cards =
		services.length + 1 > VISIBLE_CARDS_COUNT
			? [
					...services.slice(-VISIBLE_CARDS_COUNT),
					...services,
					...services.slice(0, VISIBLE_CARDS_COUNT),
			  ]
			: services;
	const clonesCount = {
		head: VISIBLE_CARDS_COUNT,
		tail: VISIBLE_CARDS_COUNT,
	};

	const [stepWidth, setStepWidth] = useState(1);
	const [offset, setOffset] = useState(-clonesCount.head * stepWidth);
	const [transitionDuration, setTransitionDuration] =
		useState(TRANSITION_DURATION);

	const [centerCardI, setCenterCardI] = useState(
		VISIBLE_CARDS_COUNT === 5
			? VISIBLE_CARDS_COUNT + 2
			: VISIBLE_CARDS_COUNT + 1
	);
	const [swipeStart, setSwipeStart] = useState();

	const handlerClick = (i, e) => {
		// e.preventDefault();
		if (windowWidth <= windowWidthTablet) {
			return;
		}
		setCenterCardI(i);

		if (transitionDuration === 0) {
			setTransitionDuration(TRANSITION_DURATION);
		}

		/* Laptop */
		if (VISIBLE_CARDS_COUNT === 5) {
			// right shift
			if (i < centerCardI) {
				// first visible card
				if (i < centerCardI - 1) {
					setOffset((current) => current + stepWidth * 2);
					return;
				}
				// nth(2) visible card
				setOffset((current) => current + stepWidth);
				return;
			}
			// center visible card
			if (i === centerCardI) {
				console.log('center card clack', i);
				return;
			}
			//left shift
			if (i > centerCardI) {
				// nth(5) visible card
				if (i === centerCardI + 2) {
					setOffset((current) => current - stepWidth * 2);
					return;
				}
				// nth(4) visible card
				setOffset((current) => current - stepWidth);
			}
		}

		/* Tablet */
		if (VISIBLE_CARDS_COUNT === 3) {
			// right shift
			if (i < centerCardI) {
				setOffset((current) => current + stepWidth);
				return;
			}
			// center visible card
			if (i === centerCardI) {
				console.log('center card clack', i);
				return;
			}
			//left shift
			if (i > centerCardI) {
				setOffset((current) => current - stepWidth);
			}
		}
	};

	const handlerSwipeStart = (e) => {
		// console.log(e.changedTouches[0].clientX);
		if (transitionDuration === 0) {
			setTransitionDuration(TRANSITION_DURATION);
		}
		setSwipeStart(e.changedTouches[0].clientX);
	};
	const handlerSwipeMove = (e) => {
		// console.log(e);
		const currentSwipePosition = e.changedTouches[0].clientX;
		const swipeDistance = (currentSwipePosition - swipeStart) / 16 / stepWidth;

		// Вычисляем новое смещение, не применяя его окончательно
		const tempOffset = offset + swipeDistance;
		// console.log('offset', offset);
		// console.log('swipeDistance', swipeDistance);
		setOffset(tempOffset);
	};
	const handlerSwipeEnd = (e) => {
		const swipeDistance = e.changedTouches[0].clientX - swipeStart;
		// Swipe right
		if (swipeDistance > 50) {
			setOffset((current) => current + stepWidth);
			setCenterCardI((current) => current - 1);
			// setOffset((current) =>  Math.ceil(current / stepWidth) * stepWidth);
			setSwipeStart(null);
			return;
		}

		// Swipe left
		if (swipeDistance < -50) {
			setOffset((current) => current - stepWidth);
			setCenterCardI((current) => current + 1);
			// setOffset((current) => Math.floor(current / stepWidth) * stepWidth);
			setSwipeStart(null);
		}
	};

	const handlerSizeCard = (i) => {
		/* Laptop */
		if (VISIBLE_CARDS_COUNT === 5) {
			let classCard;

			switch (i) {
				// nth(2) and nth(4) visible cards
				case centerCardI + 1:
				case centerCardI - 1:
					classCard = `${s.middleCard}`;
					break;

				// nth(3) visible card
				case centerCardI:
					classCard = s.card;
					break;

				// other cards
				default:
					classCard = ` ${s.smallCard}`;
					break;
			}
			return classCard;
		}

		/* Tablet, Phone */
		if (VISIBLE_CARDS_COUNT === 3) {
			if (windowWidth > windowWidthPhone) {
				if (i === centerCardI) {
					return s.middleCard;
				}
			}
			return s.smallCard;
		}
	};

	const handlerTransformCard = (i) => {
		let styleCard;

		/* Laptop */
		if (VISIBLE_CARDS_COUNT === 5) {
			//left small cards
			if (i < centerCardI - 1) {
				styleCard = returnStyleCard(LEFT_SMALL_CARD, i);
			}
			// left middle card
			if (i === centerCardI - 1) {
				styleCard = returnStyleCard(LEFT_MIDDLE_CARD, i);
			}
			// center card
			if (i === centerCardI) {
				styleCard = returnStyleCard(CENTER_CARD, i);
			}
			//right middle card
			if (i === centerCardI + 1) {
				styleCard = returnStyleCard(RIGHT_MIDDLE_CARD, i);
			}
			//right small cards
			if (i > centerCardI + 1) {
				styleCard = returnStyleCard(RIGHT_SMALL_CARD, i);
			}
		}

		/* Tablet */
		if (VISIBLE_CARDS_COUNT === 3) {
			//left small cards
			if (i < centerCardI) {
				styleCard = returnStyleCard(LEFT_SMALL_CARD, i);
			}
			// center card
			if (i === centerCardI) {
				styleCard = returnStyleCard(CENTER_CARD, i);
			}
			//right small cards
			if (i > centerCardI) {
				styleCard = returnStyleCard(RIGHT_SMALL_CARD, i);
			}
		}

		return styleCard;
	};

	function returnStyleCard(typeCard, i) {
		let styleCard;

		/* Laptop */
		if (VISIBLE_CARDS_COUNT === 5) {
			switch (typeCard) {
				case LEFT_SMALL_CARD:
					styleCard = {
						transition: `all ${transitionDuration}ms`,
						// transform: `translateX(${stepWidth * i}em)`,
						transform: `translateX(${
							stepWidth * i - 17
						}px) scale(${SCALE_SMALL_CARD})`,
					};
					break;

				case LEFT_MIDDLE_CARD:
					if (windowWidth < 1551 && windowWidth > 1024) {
						styleCard = {
							transition: `all ${transitionDuration}ms`,
							// transform: `translateX(${stepWidth * i - 7}em)`,
							transform: `translateX(${
								stepWidth * i - 205
							}px) scale(${SCALE_MIDDLE_CARD})`,
						};
						break;
					}
					if (windowWidth < 1025) {
						styleCard = {
							transition: `all ${transitionDuration}ms`,
							transform: `translateX(${
								stepWidth * i - 210
							}px) scale(${SCALE_MIDDLE_CARD})`,
							// transform: `translateX(${stepWidth * i - 6}em)`,
						};
						break;
					}
					styleCard = {
						transition: `all ${transitionDuration}ms`,
						// transform: `translateX(${stepWidth * i - 2.5}em)`, //40
						transform: `translateX(${
							stepWidth * i - 70
						}px) scale(${SCALE_MIDDLE_CARD})`, //40
					};
					break;

				case CENTER_CARD:
					if (windowWidth < 1551 && windowWidth > 1024) {
						styleCard = {
							transition: `all ${transitionDuration}ms`,
							// transform: `translateX(${stepWidth * i - 7}em)`,
							transform: `translateX(${
								stepWidth * i - 260
							}px) scale(${SCALE_CENTER_CARD})`,
						};
						break;
					}
					if (windowWidth < 1025) {
						styleCard = {
							transition: `all ${transitionDuration}ms`,
							transform: `translateX(${
								stepWidth * i - 335
							}px) scale(${SCALE_CENTER_CARD})`,
							// transform: `translateX(${stepWidth * i - 6}em)`,
						};
						break;
					}
					styleCard = {
						transition: `all ${transitionDuration}ms`,
						// transform: `translateX(${stepWidth * i - 2.5}em)`, //40
						transform: `translateX(${
							stepWidth * i - 40
						}px) scale(${SCALE_CENTER_CARD})`, //40
					};
					break;

				case RIGHT_MIDDLE_CARD:
					if (windowWidth < 1551 && windowWidth > 1024) {
						styleCard = {
							transition: `all ${transitionDuration}ms`,
							transform: `translateX(${
								stepWidth * i - 320
							}px) scale(${SCALE_MIDDLE_CARD})`,
							// transform: `translateX(${stepWidth * i}em)`,
						};
						break;
					}
					if (windowWidth < 1025) {
						styleCard = {
							transition: `all ${transitionDuration}ms`,
							transform: `translateX(${
								stepWidth * i - 460
							}px) scale(${SCALE_MIDDLE_CARD})`,
							// transform: `translateX(${stepWidth * i}em)`,
						};
						break;
					}
					styleCard = {
						transition: `all ${transitionDuration}ms`,
						// transform: `translateX(${stepWidth * i + 5.125}em)`, //50
						transform: `translateX(${
							stepWidth * i - 20
						}px) scale(${SCALE_MIDDLE_CARD})`, //50
					};
					break;

				case RIGHT_SMALL_CARD:
					if (windowWidth < 1551 && windowWidth > 1024) {
						styleCard = {
							transition: `all ${transitionDuration}ms`,
							transform: `translateX(${
								stepWidth * i - 500
							}px) scale(${SCALE_SMALL_CARD})`,
							// transform: `translateX(${stepWidth * i - 4.15}em)`,
						};
						break;
					}
					if (windowWidth < 1025) {
						styleCard = {
							transition: `all ${transitionDuration}ms`,
							transform: `translateX(${
								stepWidth * i - 650
							}px) scale(${SCALE_SMALL_CARD})`,
							// transform: `translateX(${stepWidth * i - 2.5}em)`,
						};
						break;
					}
					styleCard = {
						transition: `all ${transitionDuration}ms`,
						// transform: `translateX(${stepWidth * i + 2.5}em)`, //40
						transform: `translateX(${
							stepWidth * i - 62
						}px) scale(${SCALE_SMALL_CARD})`, //40
					};
					break;
			}
		}

		/* Tablet */
		if (windowWidth > windowWidthPhone && VISIBLE_CARDS_COUNT === 3) {
			switch (typeCard) {
				case LEFT_SMALL_CARD:
					styleCard = {
						transition: `all ${transitionDuration}ms`,
						transform: `translateX(${
							stepWidth * i - 17
						}px) scale(${SCALE_SMALL_CARD})`,
						// transform: `translateX(${stepWidth * i}em)`,
					};
					break;

				case CENTER_CARD:
					styleCard = {
						transition: `all ${transitionDuration}ms`,
						transform: `translateX(${
							stepWidth * i - 135
						}px) scale(${SCALE_MIDDLE_CARD})`,
						// transform: `translateX(${stepWidth * i - 7}em)`,
					};
					break;

				case RIGHT_SMALL_CARD:
					styleCard = {
						transition: `all ${transitionDuration}ms`,
						transform: `translateX(${
							stepWidth * i - 242
						}px) scale(${SCALE_SMALL_CARD})`,
						// transform: `translateX(${stepWidth * i - 10.9}em)`,
					};
					break;
			}
		}

		/* Phone */
		if (windowWidth <= windowWidthPhone) {
			styleCard = {
				transition: `all ${transitionDuration}ms`,
				transform: `translateX(${stepWidth * i}px)
				scale(${SCALE_SMALL_CARD})
        `,
			};
			// switch (typeCard) {
			// 	case LEFT_SMALL_CARD:
			// 		styleCard = {
			// 			transition: `all ${transitionDuration}ms`,
			// 			// transform: `translateX(${stepWidth * i - windowWidth * 0.21}px)
			// 			transform: `translateX(${stepWidth * i}px)
			// 			scale(${SCALE_SMALL_CARD})
			// 			`,
			// 		};
			// 		break;

			// 	case CENTER_CARD:
			// 		styleCard = {
			// 			transition: `all ${transitionDuration}ms`,
			// 			// transform: `translateX(${stepWidth * i - windowWidth}px)
			// 			transform: `translateX(${stepWidth * i}px)
			// 			scale(${SCALE_MIDDLE_CARD})
			// 			`,
			// 		};
			// 		break;

			// 	case RIGHT_SMALL_CARD:
			// 		styleCard = {
			// 			transition: `all ${transitionDuration}ms`,
			// 			transform: `translateX(${
			// 				// stepWidth * i - windowWidth * 0.7
			// 				stepWidth * i - 350
			// 			}px) scale(${SCALE_SMALL_CARD})`,
			// 		};
			// 		break;
			// }
		}

		return styleCard;
	}

	useEffect(() => {
		// console.log('sliderRef', sliderRef);
		setStepWidth(sliderRef.current.children[0].clientWidth * SCALE_SMALL_CARD);
		setOffset(-clonesCount.head * stepWidth);
	}, [stepWidth, sliderRef]);

	useEffect(() => {
		switch (offset) {
			case 0: // Laptop: centerCardI = 2
				setTimeout(() => {
					setTransitionDuration(0);
					setCenterCardI(
						(currentI) =>
							cards.length - VISIBLE_CARDS_COUNT + currentI - clonesCount.tail
					);
					setOffset(
						-(
							stepWidth *
							(cards.length - VISIBLE_CARDS_COUNT - clonesCount.tail)
						)
					);
				}, TRANSITION_DURATION);
				break;

			case -stepWidth: // Laptop ? centerCardI = 3 : centerCardI = 2
				setTimeout(() => {
					setTransitionDuration(0);
					setCenterCardI(
						(currentI) =>
							cards.length - VISIBLE_CARDS_COUNT + currentI - clonesCount.tail
						//   (	cards.length - VISIBLE_CARDS_COUNT + 2 -clonesCount.tail + 1)
					);
					setOffset(
						-(
							stepWidth *
							(cards.length - VISIBLE_CARDS_COUNT - clonesCount.tail + 1)
						)
					);
				}, TRANSITION_DURATION);
				break;

			case -(stepWidth * (cards.length - VISIBLE_CARDS_COUNT)): // Laptop: centerCardI = (-3)
				setTimeout(() => {
					setTransitionDuration(0);
					setCenterCardI(clonesCount.head + 2);
					setOffset(-(stepWidth * clonesCount.head));
				}, TRANSITION_DURATION);
				break;

			case -(stepWidth * (cards.length - VISIBLE_CARDS_COUNT - 1)): // Laptop ? centerCardI = (-4) : centerCardI = (-3)
				setTimeout(() => {
					setTransitionDuration(0);
					VISIBLE_CARDS_COUNT === 5
						? setCenterCardI(clonesCount.head + 1)
						: setCenterCardI(clonesCount.head);

					setOffset(-(stepWidth * (clonesCount.head - 1)));
				}, TRANSITION_DURATION);
				break;
		}
	}, [
		offset,
		// cards,
		// clonesCount,
	]);

	// console.log('rer');
	return (
		<div className={s.container}>
			<div
				ref={sliderRef}
				style={
					services.length + 1 > VISIBLE_CARDS_COUNT
						? {
								transition: `all ${transitionDuration}ms`,
								transform: `translateX(${offset}px)`,
								width: `${stepWidth * cards.length}px`,
						  }
						: {
								transform: `translateX(0px)`,
								width: `${stepWidth * cards.length}px`,
						  }
				}
				className={s.slider}
				onTouchStart={handlerSwipeStart}
				onTouchEnd={handlerSwipeEnd}>
				{cards.map((card, i) => (
					<ServiceCard
						style={handlerTransformCard(i)}
						// 	transitionDuration={transitionDuration}
						classes={handlerSizeCard(i)}
						serviceName={`id:${card.id} (i=${i})`}
						key={i}
						imgUrl={img}
						clickFunction={(e) => handlerClick(i, e)}
					/>
				))}
			</div>
		</div>
	);
};
