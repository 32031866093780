const INLINE_STYLE = [
	{ label: 'Bold', style: 'BOLD' },
	{ label: 'Italic', style: 'ITALIC' },
];

const BLOCK_TYPES = [
	{ label: 'UL', style: 'unordered-list-item' },
	{ label: 'OL', style: 'ordered-list-item' },
];

const ALIGNMENT_TYPES = [
	{ label: 'Left', style: 'left' },
	{ label: 'Center', style: 'center' },
	{ label: 'Right', style: 'right' },
	{ label: 'Justify', style: 'justify' },
];

function blockStyleFn(contentBlock) {
	const type = contentBlock.getType();

	// console.log(type);
	// if (type === 'unstyled') {
	return `align-${contentBlock.getData().get('text-align')}`;
	// }
}

export { INLINE_STYLE, BLOCK_TYPES, ALIGNMENT_TYPES, blockStyleFn };
