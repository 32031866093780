import React, { useContext, useState } from 'react';
// import { EditorState, Modifier, convertToRaw } from 'draft-js';
import { useDispatch, useSelector } from 'react-redux';
import { FieldList } from './fieldList';
import { addFieldToTheList } from '../../../../store/features/document/documentSlice';
import { selectDocument } from '../../../../store/selectors';
import { EditorContext } from '../content/contentContainer';
import { creatorEditorStateWithEntity } from '../../../../core/utils/functions/creatorEditorStateWithEntity';

export const FieldListContainer = () => {
	const { editorState, handlerEditorState, dispatchCurrentContent } =
		useContext(EditorContext);
	const dispatch = useDispatch();
	const fieldList = useSelector(selectDocument).fieldList;
	const [fieldName, setFieldName] = useState('');
	const [fieldType, setFieldType] = useState('string');

	const createField = () => {
		const validName = checkLastSymbolInInput(fieldName);

		const result = fieldList.find((field) => field.name === validName);

		if (!validName.length) return;
		if (result === undefined) {
			const newEditorState = creatorEditorStateWithEntity(
				editorState,
				fieldType,
				validName
			);
			handlerEditorState(newEditorState);
			dispatchCurrentContent(newEditorState);

			if (fieldType === 'date') {
				dispatch(
					addFieldToTheList({
						name: validName,
						type: fieldType,
						value: '',
						dateFormatValue: '',
						key: editorState.getCurrentContent().getLastCreatedEntityKey(),
					})
				);

				setFieldName('');
				return;
			}

			dispatch(
				addFieldToTheList({
					name: validName,
					type: fieldType,
					value: '',
					key: editorState.getCurrentContent().getLastCreatedEntityKey(),
				})
			);
		}

		setFieldName('');
		// console.log(convertToRaw(editorState.getCurrentContent()));
	};

	function checkLastSymbolInInput(str) {
		const lastSymbol = str[str.length - 1];
		const lastButOneSymbol = str[str.length - 2];
		let validName = str;

		if (/[-\s,\()]/.test(lastSymbol) && /[,\s]/.test(lastButOneSymbol)) {
			validName = str
				.split('')
				.slice(0, str.length - 2)
				.join('');

			return validName;
		}

		if (/[-\s,\(]/.test(lastSymbol)) {
			validName = str
				.split('')
				.slice(0, str.length - 1)
				.join('');
		}

		return validName;
	}

	return (
		<FieldList
			editorState={editorState}
			fieldList={fieldList}
			fieldName={fieldName}
			fieldType={fieldType}
			setFieldName={setFieldName}
			setFieldType={setFieldType}
			createField={createField}
			handlerEditorState={handlerEditorState}
		/>
	);
};
