import { useRef, useState } from 'react';
import s from './dropdownMenu.module.css';
import { useOutsideClick } from '../../../core/hooks/useOutsideClick';

export const DropdownMenu = ({ list, classes }) => {
	const dropdownRef = useRef(null);
	const [openMenu, setOpenMenu] = useState(false);

	const handlerMenuClose = () => setOpenMenu(false);

	useOutsideClick(dropdownRef, handlerMenuClose, openMenu);

	return (
		<div className={classes} onClick={() => setOpenMenu(!openMenu)}>
			<div className={s.menu}>
				{openMenu && (
					<ul ref={dropdownRef} className={s.menuList}>
						{list.map((el, i) => (
							<li key={i} onClick={el.clickFunction}>
								{el.name}
							</li>
						))}
					</ul>
				)}
			</div>
		</div>
	);
};
