import { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import s from './formDocumentPage.module.css';
import { rubles } from 'rubles';
import { Button } from '../../common/button/button';
import { Input } from '../../common/input/input';
import {
	setFieldValue,
	setFieldDateFormatValue,
} from '../../../store/features/document/documentSlice';

const Item = memo(({ field }) => {
	const dispatch = useDispatch();

	const checkTypeInput = (inputType) => {
		// console.log(inputType);

		// if (inputType === 'date') return 'text';

		if (inputType === 'money') return 'number';

		return inputType;
	};

	const checkValueInput = (field) => {
		if (field.type === 'date') return field.dateFormatValue;

		if (field.type === 'money') {
			let newValue = '';

			for (let i = 0; i < field.value.length; i++) {
				if (/\s/.test(field.value[i])) break;

				newValue += field.value[i];
			}
			return newValue;
		}

		return field.value;
	};

	const handlerInput = useCallback(
		(e) => {
			if (field.type === 'money') {
				let num = +e.target.value;

				dispatch(
					setFieldValue({
						key: field.key,
						value: `${Math.floor(num * 100) / 100} (${rubles(num)})`,
					})
				);

				return;
			}

			if (field.type === 'date') {
				dispatch(
					setFieldValue({
						key: field.key,
						value: e.target.value.split('-').reverse().join('.').trimStart(),
					})
				);

				dispatch(
					setFieldDateFormatValue({
						key: field.key,
						value: e.target.value.trimStart(),
					})
				);

				return;
			}

			dispatch(
				setFieldValue({
					key: field.key,
					value: e.target.value.trimStart(),
				})
			);
		},
		[field]
	);

	// const focusFn = useCallback(
	// 	(e) => {
	// 		if (field.type === 'date') {
	// 			e.target.type = 'date';
	// 		}
	// 	},
	// 	[field]
	// );

	// const blurFn = useCallback(
	// 	(e) => {
	// 		if (field.type === 'date') {
	// 			e.target.type = 'text';
	// 		}
	// 	},
	// 	[field]
	// );
	// const handlerInput = (e, field) => {
	// 	if (field.type === 'money') {
	// 		let num = +e.target.value;

	// 		dispatch(
	// 			setFieldValue({
	// 				key: field.key,
	// 				value: `${Math.floor(num * 100) / 100} (${rubles(num)})`,
	// 			})
	// 		);

	// 		return;
	// 	}

	// 	if (field.type === 'date') {
	// 		dispatch(
	// 			setFieldValue({
	// 				key: field.key,
	// 				value: e.target.value.split('-').reverse().join('.').trimStart(),
	// 			})
	// 		);

	// 		return;
	// 	}

	// 	dispatch(
	// 		setFieldValue({
	// 			key: field.key,
	// 			value: e.target.value.trimStart(),
	// 		})
	// 	);
	// };

	return (
		<label className={s.label}>
			{field.name}
			<Input
				classes={s.input}
				type={checkTypeInput(field.type)}
				inputValue={checkValueInput(field)}
				changeFunction={handlerInput}
				placeholder={field.name}
				required={true}
				// changeFunction={(e) => handlerInput(e, field)}
				// focusFunction={focusFn}
				// blurFunction={blurFn}
			/>
		</label>
	);
});

export const Content = ({ fieldList }) => {
	// console.log(regionList);
	return (
		<>
			{fieldList.map((field) => {
				if (
					field.type !== 'court' &&
					field.type !== 'courtAddress' &&
					field.type !== 'signature'
				) {
					return <Item key={field.key} field={field} />;
				}
			})}

			<Button type={'submit'} text='Создать документ' />
		</>
	);
};
