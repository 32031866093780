import { useCallback, useContext, useState } from 'react';
import s from './documentMenu.module.css';
import { ReactComponent as OpenFileIcon } from '../../../../../img/button/openFile.svg';
import { ReactComponent as Save } from '../../../../../img/button/save.svg';
import { ReactComponent as SaveAs } from '../../../../../img/button/saveAs.svg';
import { ReactComponent as FilePDF } from '../../../../../img/button/filePDF.svg';
import { ReactComponent as CloseFile } from '../../../../../img/button/closeFile.svg';
import { ReactComponent as CheckFile } from '../../../../../img/button/checkFile.svg';
import { ReactComponent as AddFile } from '../../../../../img/button/addFile.svg';
import { ModalWindow } from '../../../../common/modalWindow/modalWindow';
import { SaveAsContainer } from '../../saveAs/saveAsContainer';
import { DocInfo } from '../../docInfo/docInfo';
import { OpenFileContainer } from '../../openFile/openFileContainer';
import { EditorContext } from '../contentContainer';
import { CloseDoc } from '../closeDoc/closeDoc';

export const DocumentMenu = () => {
	const { doc, closeDocument } = useContext(EditorContext);

	const [options, setOptions] = useState('file');
	const [openModalDocInfo, setOpenModalDocInfo] = useState(false);
	const [openModalOpenFile, setOpenModalOpenFile] = useState(false);
	const [openModalCloseDoc, setOpenModalCloseDoc] = useState(false);

	const setOptionsPanel = (option) => setOptions(option);

	const handlerOpenDocInfo = () => setOpenModalDocInfo(!openModalDocInfo);

	const openDoc = useCallback(() => setOpenModalOpenFile(true), []);

	const handlerCloseDocument = () => {
		setOpenModalCloseDoc(true);
	};

	return (
		<div className={s.container}>
			<div role='tablist' className={s.options}>
				<button
					className={s.tab}
					role='tab'
					aria-selected={options === 'file'}
					aria-controls='fileContent'
					onClick={() => setOptionsPanel('file')}>
					Файл
				</button>

				<button
					className={s.tab}
					role='tab'
					aria-selected={options === 'edit'}
					aria-controls='editContent'
					onClick={() => setOptionsPanel('edit')}>
					Редактирование
				</button>

				<button
					className={s.tab}
					role='tab'
					aria-selected={options === 'info'}
					aria-controls='infoContent'
					onClick={() => setOptionsPanel('info')}>
					Сведения
				</button>
			</div>

			<div
				className={s.content}
				style={options === 'file' ? { borderRadius: '0 10px 10px' } : {}}>
				{options === 'file' && (
					<div className={s.tabPanel} id='fileContent' role='tabpanel'>
						<button className={s.btn} onClick={openDoc}>
							<OpenFileIcon /> Открыть документ
						</button>
						<button className={s.btn}>
							<Save /> Сохранить
						</button>
						<button className={s.btn}>
							<SaveAs /> Сохранить как черновик
						</button>
						<button className={s.btn}>
							<FilePDF /> Сохранить в PDF
						</button>
						<button className={s.btn} onClick={handlerCloseDocument}>
							<CloseFile /> Закрыть без изменений
						</button>
						<button className={s.btn}>
							<CheckFile /> Отправить на модерацию
						</button>
					</div>
				)}

				{options === 'edit' && (
					<div className={s.tabPanel} id='editContent' role='tabpanel'>
						<button className={s.btn} onClick={handlerOpenDocInfo}>
							<AddFile /> Заполнить описание
						</button>
					</div>
				)}

				{options === 'info' && (
					<div className={s.tabPanel} id='infoContent' role='tabpanel'>
						<button className={s.btn}>Статус: Опубликован</button>
						<button className={s.btn}>Дата создания: 12.02.2023</button>
						<button className={s.btn}>Дата изменения: 12.02.2023</button>
						<button className={s.btn}>Автор: 1 юридический</button>
					</div>
				)}
			</div>

			{openModalDocInfo && (
				<ModalWindow
					classes={s.modalWindow}
					// clickFunction={() => setOpenModalSaveAs(!openModalSaveAs)}
					content={<DocInfo handlerOpenDocInfo={handlerOpenDocInfo} />}
				/>
			)}

			{openModalOpenFile && (
				<ModalWindow
					clickFunction={() => setOpenModalOpenFile(!openModalOpenFile)}
					content={
						<OpenFileContainer setOpenModalOpenFile={setOpenModalOpenFile} />
					}
				/>
			)}

			{openModalCloseDoc && (
				<ModalWindow
					clickFunction={() => setOpenModalCloseDoc(!openModalCloseDoc)}
					content={<CloseDoc setOpenModalCloseDoc={setOpenModalCloseDoc} />}
				/>
			)}
		</div>
	);
};
