import { CircleWithShadow } from '../../circleWithShadow/circleWithShadow';
import s from './menuItemMobile.module.css';
import { ReactComponent as ArrowFlag } from '../../../../img/linkArrowFlag.svg';
export const MenuItemMobile = ({ icon, name, clickFunction }) => {
	return (
		<div className={s.container} onClick={clickFunction}>
			<div>
				<CircleWithShadow icon={icon} />

				<p>{name}</p>
			</div>

			<ArrowFlag />
		</div>
	);
};
