import s from './userInfo.module.css';
import { ReactComponent as EditIcon } from '../../../../../../img/editIcon.svg';

export const UserInfo = () => {
	return (
		<div className={s.userInfo}>
			<div className={s.titleBlock}>
				<h3 className={s.title}>Личная информация</h3>

				<div className={s.edit}>
					<EditIcon /> <p>Редактировать</p>
				</div>
			</div>

			<div className={s.info}>
				<p>ФИО: Иванов Иван Иванович</p>

				<p>Паспорт: 22 15 454 987</p>

				<p>Выдан: ОУФМС России по Нижегородской области</p>

				<p>Дата выдачи: 15.03.2012</p>

				<p>Код подразделения: 520-005</p>

				<p>Дата рождения: 12.07.1990</p>

				<p>Адрес регистрации: г. Нижний Новгород ул. Дьяконова д. 14 кв 12</p>

				<p>Фактический адрес: г. Воронеж ул. Дыбенко д. 235 кв. 46</p>

				<p>Почта: exemple@mail.com</p>

				<p>Номер телефона: +7 (999) 342 57-16</p>

				<p>ИНН: 164216842616546984</p>

				<p>СНИЛС: 16421684261</p>
			</div>
		</div>
	);
};
