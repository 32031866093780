import s from './textarea.module.css';

export const Textarea = ({
	refTextarea,
	type,
	rows,
	culms,
	nameTextarea,
	classes,
	textareaValue,
	clickFunction,
	changeFunction,
	blurFunction,
	focusFunction,
	keyDownEnterFunction,
	placeholder,
	required,
	checked,
	disabled,
	maxLength,
}) => {
	return (
		<textarea
			ref={refTextarea}
			type={type}
			rows={rows}
			culms={culms}
			name={nameTextarea}
			className={`${s.textarea} ${classes}`}
			onClick={clickFunction}
			onChange={changeFunction}
			onBlur={blurFunction}
			onFocus={focusFunction}
			onKeyDownCapture={(e) => {
				if (e.key === 'Enter') {
					keyDownEnterFunction();
				}
			}}
			placeholder={placeholder}
			required={required}
			checked={checked}
			disabled={disabled}
			maxLength={maxLength}>
			{textareaValue}
		</textarea>
	);
};
