import s from './user.module.css';
import { UserDocuments } from './userDocuments/userDocuments';
import { UserInfo } from '../../../userOffice/userData/userInfo/userInfo';
import { PaymentOnAccount } from '../../../userOffice/userData/payData/paymentOnAccount/paymentOnAccount';
import { ReplenishmentHistory } from '../../../userOffice/userData/replenishmentHistory/replenishmentHistory';

export const User = () => {
	return (
		<div className={s.container}>
			<UserInfo />

			<div className={s.payBlock}>
				<h3 className={s.title}>Платежные данные</h3>

				<div className={s.payData}>
					<PaymentOnAccount />

					<ReplenishmentHistory />
				</div>
			</div>

			<UserDocuments />
		</div>
	);
};
