import s from './burgerMenuMobile.module.css';

export const BurgerMenuMobile = ({ clickFunction, classes, isOpen }) => {
	return (
		<div className={`${s.btnMenu} ${classes}`}>
			<input
				id={s.menuToggle}
				checked={isOpen}
				onChange={() => {}}
				type='checkbox'
			/>
			<label
				className={s.menuToggleLabel}
				htmlFor={s.menuToggle}
				onClick={clickFunction}
			/>
			<div className={s.burgerMenu}></div>
		</div>
	);
};
