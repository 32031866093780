import s from './governmentAgenciesList.module.css';
import fns from '../../../../../img/servicesGovernment/fns.svg';
import pristav from '../../../../../img/servicesGovernment/pristav.svg';
import prokuratura from '../../../../../img/servicesGovernment/prokuratura.svg';
import arbitrajsud from '../../../../../img/servicesGovernment/arbitrajsud.svg';
import advokat from '../../../../../img/servicesGovernment/advokat.svg';
import notariat from '../../../../../img/servicesGovernment/notariat.svg';
import allsud from '../../../../../img/servicesGovernment/allsud.svg';
import { Link } from '../../../../common/link/link';
import { ReactComponent as LinkArrowFlag } from '../../../../../img/linkArrowFlag.svg';

export const GovernmentAgenciesList = () => {
	return (
		<ul className={s.list}>
			<Item icon={allsud} text={'Суды общей юрисдикции'} />
			<Item icon={advokat} text={'Адвокатура'} />
			<Item icon={pristav} text={'Судебные приставы'} />
			<Item icon={fns} text={'Налоговая'} />
			<Item icon={arbitrajsud} text={'Арбитражные суды'} />
			<Item icon={notariat} text={'Нотариат'} />
			<Item icon={prokuratura} text={'Прокуратура'} />
		</ul>
	);
};

function Item({ icon, text }) {
	return (
		<Link href='#' className={s.link}>
			<img src={icon} alt='icon' /> {text}
			<LinkArrowFlag />
		</Link>
	);
}
