import { useContext } from 'react';
import s from './imageDecorator.module.css';
import { ImgEditorContext } from '../../../toolbar/imgEditorControl/imgEditorControl';

export const ImageDecorator = (props) => {
	const { openImgSetting, setOpenImgSetting, setImgEntityKey } =
		useContext(ImgEditorContext);

	const { contentState, entityKey, offsetKey } = props;
	const { src, value, width, height } = contentState
		.getEntity(entityKey)
		.getData();

	// console.log(value);
	return (
		<>
			<span data-offset-key={offsetKey}>
				<span data-text='true'> </span>

				<img
					src={src}
					style={{
						height,
						width,
					}}
					className={s.img}
					onClick={() => {
						setImgEntityKey(entityKey);
						setOpenImgSetting(true);
					}}
					alt={src ? 'img' : 'Подпись'}
					data-custom-attribute='Ваши данные'
				/>

				<span data-text='true'> </span>
			</span>
		</>
	);
};
