import { useState, useEffect, useCallback, createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EditorState, convertToRaw } from 'draft-js';
import { Content } from './content';
import { sendDocument } from '../../../../store/features/document/thunks';
import { selectDocument, selectServices } from '../../../../store/selectors';
import {
	setEditorContent,
	setFieldList,
	setIsMain,
	setIsPopular,
	setStateDocument,
} from '../../../../store/features/document/documentSlice';
import { decoratorList } from '../textEditor/config/decoratorList/decoratorList';

export const EditorContext = createContext();

export const ContentContainer = () => {
	const dispatch = useDispatch();
	const serviceList = useSelector(selectServices).serviceList;
	const doc = useSelector(selectDocument);

	// Local editorState created, because editorContent don't have information about SelectionState.
	const [editorState, setEditorState] = useState(
		EditorState.createEmpty(decoratorList)
	);

	const [openModalSaveAs, setOpenModalSaveAs] = useState(false);
	const [openRequestResultWindow, setOpenRequestResultWindow] = useState(false);
	const [requestResult, setRequestResult] = useState('');

	const toggleIsMain = useCallback((e) => {
		dispatch(setIsMain(e.target.checked));
	}, []);

	const toggleIsPopular = useCallback((e) => {
		dispatch(setIsPopular(e.target.checked));
	}, []);

	const handlerEditorState = (editorState) => {
		setEditorState(editorState);
		// dispatch(setEditorContent(convertToRaw(editorState.getCurrentContent())));
	};
	const dispatchCurrentContent = (editorState) => {
		dispatch(setEditorContent(convertToRaw(editorState.getCurrentContent())));
	};

	const handlerSaveDocument = async () => {
		const fieldList = checkingForUseAllFieldsInDocument();

		await dispatch(setFieldList(fieldList));
		console.log('dis1 done');

		await dispatch(sendDocument()).then((res) => {
			// console.log(res);

			if (!res.payload) {
				setOpenModalSaveAs(true);
				return;
			}

			if (res.payload.statusText === 'OK') {
				setRequestResult('Success!');
				setOpenRequestResultWindow(true);
				return;
			}
			setRequestResult(res.payload.message);
			setOpenRequestResultWindow(true);
		});
	};

	const closeDocument = useCallback(() => {
		const newEditorState = EditorState.createEmpty(decoratorList);
		dispatch(setStateDocument());
		setEditorState(newEditorState);
	}, []);

	function checkingForUseAllFieldsInDocument() {
		const arrayEntityList = Object.values(
			convertToRaw(editorState.getCurrentContent()).entityMap
		);

		const newFieldList = doc.fieldList.filter((field) => {
			return arrayEntityList.find((entity) => field.key === entity.data.key);
		});
		console.log('check', newFieldList);
		return newFieldList;
	}

	const contextValue = {
		editorState,
		setEditorState,
		handlerEditorState,
		doc,
		serviceList,
		closeDocument,
		handlerSaveDocument,
		openModalSaveAs,
		setOpenModalSaveAs,
		toggleIsMain,
		toggleIsPopular,
		checkingForUseAllFieldsInDocument,
		setOpenRequestResultWindow,
		setRequestResult,
		dispatchCurrentContent,
	};

	return (
		<EditorContext.Provider value={contextValue}>
			<Content
				openRequestResultWindow={openRequestResultWindow}
				setOpenRequestResultWindow={setOpenRequestResultWindow}
				requestResult={requestResult}
				setRequestResult={setRequestResult}
			/>
		</EditorContext.Provider>
	);
};
