import s from './moderation.module.css';
import { Input } from '../../../../common/input/input';
// import { Button } from '../../../common/button/button';
import { ReactComponent as SearchIcon } from '../../../../../img/searchIcon.svg';
import { ReactComponent as Ok } from '../../../../../img/ok.svg';
import { DropdownMenu } from '../../../../common/dropdownMenu/dropdownMenu';

export const Moderation = () => {
	return (
		<div className={s.container}>
			<div className={s.headRow}>
				<div>
					<h3 className={s.title}>Модерация</h3>

					<div className={s.search}>
						<Input
							classes={s.input}
							// inputValue={}
							// changeFunction={}
							// clickFunction={}
							// keyDownEnterFunction={}
							placeholder={'Поиск'}
						/>

						<SearchIcon className={s.searchIcon} />
					</div>
				</div>

				<div>
					<p>Сортировать: по дате</p>

					<p>Вся история</p>
				</div>
			</div>

			<table className={s.table}>
				<thead>
					<tr className={s.row}>
						<th>Тип</th>
						<th>ID</th>
						<th>Пользователь</th>
						<th>Дата создания</th>
						<th>Доп. информация</th>
						<th>Статус</th>
						<th>Действие</th>
					</tr>
				</thead>

				<tbody>
					<Row
						type={'Запрос на добавление документа'}
						id={654498}
						userName={'Иванов Иван'}
						date={'11.11.1111'}
						info={'Документ о расторжении брака'}
						status={'Ждет рассмотрения'}
					/>
					<Row
						type={'Запрос на добавление документа'}
						id={654498}
						userName={'Иванов Иван'}
						date={'11.11.1111'}
						info={'Документ о расторжении брака'}
						status={'Ждет рассмотрения'}
					/>
					<Row
						type={'Запрос на добавление документа'}
						id={654498}
						userName={'Иванов Иван'}
						date={'11.11.1111'}
						info={'Документ о расторжении брака'}
						status={'Ждет рассмотрения'}
					/>
					<Row
						type={'Запрос на добавление документа'}
						id={654498}
						userName={'Иванов Иван'}
						date={'11.11.1111'}
						info={'Документ о расторжении брака'}
						status={'Ждет рассмотрения'}
					/>
				</tbody>
			</table>
		</div>
	);
};

function Row({ type, id, userName, date, info, status }) {
	return (
		<tr className={s.row}>
			<td>{type}</td>
			<td>{id}</td>
			<td>{userName}</td>
			<td>{date}</td>
			<td>{info}</td>
			<td>{status}</td>
			<td>
				<DropdownMenu
					list={[
						{
							name: 'Принять',
							// clickFunction: () => handlerNavigate(id),
						},
						{ name: 'Отменить' },
						{ name: 'Отложить' },
						{ name: 'Комментарии' },
					]}
				/>
			</td>
		</tr>
	);
}
