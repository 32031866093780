import { Button } from '../../../common/button/button';
import s from './usefulBlock.module.css';
import { ReactComponent as LinkArrowFlag } from '../../../../img/linkArrowFlag.svg';

export const UsefulBlock = () => {
	return (
		<div className={s.container}>
			<h3 className={`title`}>Полезное</h3>

			<div>
				<div className={s.left}>
					<p>Узнайте о всех функциях и преимуществах:</p>

					<div className={s.btns}>
						<Button
							classes={s.btn}
							text={
								<>
									Как получить консультацию <LinkArrowFlag />
								</>
							}
						/>
						<Button
							classes={s.btn}
							text={
								<>
									Как получить консультацию <LinkArrowFlag />
								</>
							}
						/>
						<Button
							classes={s.btn}
							text={
								<>
									Как получить консультацию <LinkArrowFlag />
								</>
							}
						/>
						<Button
							classes={s.btn}
							text={
								<>
									Как получить консультацию <LinkArrowFlag />
								</>
							}
						/>
						<Button
							classes={s.btn}
							text={
								<>
									Как получить консультацию <LinkArrowFlag />
								</>
							}
						/>
						<Button
							classes={s.btn}
							text={
								<>
									Как получить консультацию <LinkArrowFlag />
								</>
							}
						/>
						<Button
							classes={s.btn}
							text={
								<>
									Как получить консультацию <LinkArrowFlag />
								</>
							}
						/>
					</div>
				</div>

				<div className={s.right}></div>
			</div>
		</div>
	);
};
