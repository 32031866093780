import { useCallback, useContext } from 'react';
import s from './stickyBlock.module.css';
import { EditorContext } from '../contentContainer';
import { Button } from '../../../../common/button/button';
import { CreatorPDF } from '../../../../common/creatorPDF/creatorPDF';
import { Input } from '../../../../common/input/input';
import { FieldListContainer } from '../../fieldList/fieldListContainer';
import { ModalWindow } from '../../../../common/modalWindow/modalWindow';
import { SaveAsContainer } from '../../saveAs/saveAsContainer';
import { ReactComponent as Save } from '../../../../../img/button/save.svg';
import { ReactComponent as SaveAs } from '../../../../../img/button/saveAs.svg';
import { ReactComponent as FilePDF } from '../../../../../img/button/filePDF.svg';

export const StickyBlock = () => {
	const {
		editorState,
		handlerSaveDocument,
		openModalSaveAs,
		setOpenModalSaveAs,
		doc,
		toggleIsMain,
		toggleIsPopular,
		checkingForUseAllFieldsInDocument,
		setOpenRequestResultWindow,
		setRequestResult,
	} = useContext(EditorContext);

	const saveAs = useCallback(() => setOpenModalSaveAs(true), []);

	return (
		<>
			<div className={s.stickyBlock}>
				{/* <div className={`${s.flexBlock}`}>
					<Button
						classes={s.btn}
						text={
							<>
								<Save />
								{'Сохранить'}
							</>
						}
						clickFunction={handlerSaveDocument}
						// img={s.saveFile}
					/>

					<Button
						classes={s.btn}
						text={
							<>
								<SaveAs />
								{'Сохранить как'}
							</>
						}
						clickFunction={saveAs}
						// img={s.saveAsFile}
					/>

					<CreatorPDF
						editorState={editorState}
						text={
							<Button
								classes={s.btn}
								text={
									<>
										<FilePDF />
										{'PDF'}
									</>
								}
								// img={s.filePDF}
							/>
						}
					/>
				</div> */}

				{/* <div className={`${s.checkboxContainer}`}>
					<label>
						<Input
							type={'checkbox'}
							classes={s.checkbox}
							checked={doc.isMain}
							changeFunction={toggleIsMain}
						/>
						<span>На главную</span>
					</label>

					<label>
						<Input
							type={'checkbox'}
							classes={s.checkbox}
							checked={doc.isPopular}
							changeFunction={toggleIsPopular}
						/>
						<span>Популярные</span>
					</label>
				</div> */}

				<FieldListContainer />
			</div>

			{openModalSaveAs && (
				<ModalWindow
					classes={s.modalWindow}
					clickFunction={() => setOpenModalSaveAs(!openModalSaveAs)}
					content={
						<SaveAsContainer
							setOpenModalSaveAs={setOpenModalSaveAs}
							checkingForUseAllFieldsInDocument={
								checkingForUseAllFieldsInDocument
							}
							setOpenRequestResultWindow={setOpenRequestResultWindow}
							setRequestResult={setRequestResult}
						/>
					}
				/>
			)}
		</>
	);
};
