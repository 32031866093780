import { PayData } from './payData/payData';
import { PurchaseHistory } from './purchaseHistory/purchaseHistory';
import { ReplenishmentHistory } from './replenishmentHistory/replenishmentHistory';
import s from './userData.module.css';

import { UserInfo } from './userInfo/userInfo';

export const UserData = () => {
	return (
		<div className={s.container}>
			<section className={s.userInfoBlock}>
				<UserInfo />

				<div className={s.subscription}>
					<div className={s.card}>
						<p>Баланс</p>
						<p>1280 рублей</p>
						<p>Пополнить</p>
					</div>

					<div className={s.card}>
						<p>Подписка</p>
						<p>Осталось 12 дней</p>
						<p>Продлить</p>
					</div>
				</div>
			</section>

			<section className={s.payBlock}>
				<PayData />

				<div>
					<PurchaseHistory />

					<ReplenishmentHistory />
				</div>
			</section>
		</div>
	);
};
