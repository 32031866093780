import { useContext } from 'react';
import { RichUtils } from 'draft-js';
import { INLINE_STYLE } from '../../config/config';
// import { EditorContext } from '../../textEditor';
import { EditorControlItem } from '../editorControlItem/editorControlItem';
import { EditorContext } from '../../../content/contentContainer';

export const InlineEditorControls = () => {
	const { editorState, setEditorState } = useContext(EditorContext);

	const currentStyle = editorState.getCurrentInlineStyle();

	const toggleInlineType = (inlineType) => {
		setEditorState(RichUtils.toggleInlineStyle(editorState, inlineType));
	};

	return (
		<div className='RichEditor-controls'>
			{INLINE_STYLE.map((type) => (
				<EditorControlItem
					key={type.label}
					style={type.style}
					isActive={currentStyle.has(type.style)}
					onToggle={() => toggleInlineType(type.style)}>
					{type.label}
				</EditorControlItem>
			))}
		</div>
	);
};
