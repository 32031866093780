import { createContext, useContext, useState } from 'react';
import { EditorState, Modifier } from 'draft-js';
import s from './imgEditorControl.module.css';
import { EditorContext } from '../../../content/contentContainer';
import { creatorEditorStateWithEntity } from '../../../../../../core/utils/functions/creatorEditorStateWithEntity';

export const ImgEditorContext = createContext();

export const ImgEditorControl = () => {
	const { editorState, setEditorState } = useContext(EditorContext);
	const { openImgSetting, imgEntityKey, setImgEntityKey, setOpenImgSetting } =
		useContext(ImgEditorContext);

	const [open, setOpen] = useState(false);
	const [imgSrc, setImgSrc] = useState('');
	const [imgWidth, setImgWidth] = useState('');
	const [imgHeight, setImgHeight] = useState('');

	const handleImagePaste = () => {
		if (!imgSrc) {
			console.log('файл не выбран');
			return;
		}

		setOpen(false);
		const newEditorState = creatorEditorStateWithEntity(
			editorState,
			'image',
			'picture',
			imgSrc
		);

		// console.log(convertToRaw(newEditorState.getCurrentContent()));
		setEditorState(newEditorState);
		setImgSrc('');
	};

	const handleImageChange = () => {
		const newContent = editorState.getCurrentContent();

		if (imgHeight) {
			newContent.mergeEntityData(imgEntityKey, {
				height: `${imgHeight}px`,
			});
		}

		if (imgWidth) {
			newContent.mergeEntityData(imgEntityKey, {
				width: `${imgWidth}px`,
			});
		}

		const newEditorState = EditorState.push(
			editorState,
			newContent,
			'insert-characters'
		);

		setEditorState(newEditorState);

		setOpenImgSetting(false);
		setImgEntityKey('');
		setImgWidth('');
		setImgHeight('');
	};

	return (
		<>
			<div
				className={open ? `${s.imgItem} ${s.imgItemActive}` : s.imgItem}
				onClick={() => {
					setOpen(!open);
				}}>
				IMG
			</div>

			{open && (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<input
						type='file'
						accept='.png, .jpg, .jpeg'
						onChange={(e) => {
							let reader = new FileReader();
							reader.readAsDataURL(e.target.files[0]);
							reader.onload = (e) => {
								setImgSrc(e.target.result);
							};
						}}
						placeholder='Выберите файл'
					/>

					<button onClick={handleImagePaste}>Добавить изображение</button>
				</div>
			)}

			{openImgSetting && (
				<div>
					<input
						type='number'
						value={imgWidth}
						onChange={(e) => {
							setImgWidth(e.target.value);
						}}
						placeholder='Введите ширину'
					/>
					<input
						type='number'
						value={imgHeight}
						onChange={(e) => {
							setImgHeight(e.target.value);
						}}
						placeholder='Введите высоту'
					/>

					<button onClick={handleImageChange}>Изменить изображение</button>

					<button
						onClick={() => {
							setOpenImgSetting(false);
						}}>
						Закрыть
					</button>
				</div>
			)}
		</>
	);
};
