import { Slider } from './slider/slider';
import s from './popularBlock.module.css';

export const PopularBlock = () => {
	return (
		<div className={s.container}>
			<h3 className={`title`}>Популярные услуги</h3>

			<Slider />
		</div>
	);
};
