import { useContext } from 'react';
import { Editor } from 'draft-js';
import s from './textEditor.module.css';
import { blockStyleFn } from './config/config';
import { Toolbar } from './toolbar/toolbar';
import { EditorContext } from '../content/contentContainer';

export const TextEditor = () => {
	const { editorState, handlerEditorState, dispatchCurrentContent } =
		useContext(EditorContext);

	return (
		<div className={s.container}>
			<Toolbar />

			<Editor
				editorState={editorState}
				onChange={handlerEditorState}
				onBlur={() => dispatchCurrentContent(editorState)}
				blockStyleFn={blockStyleFn}
				placeholder='Создайте документ...'
			/>
		</div>
	);
};
