import s from './burgerMenu.module.css';

export const BurgerMenu = ({ clickFunction, classes }) => {
	return (
		<div className={`${s.btnMenu} ${classes}`}>
			<input id={s.menuToggle} type='checkbox' />
			<label
				className={s.menuToggleLabel}
				htmlFor={s.menuToggle}
				onClick={clickFunction}
			/>
			<div className={s.burgerMenu}></div>

			<p>Все категории</p>
		</div>
	);
};
