import s from './subscriptions.module.css';

export const Subscriptions = () => {
	return (
		<div className={s.container}>
			<h3 className={s.title}>Подписки</h3>

			<div className={s.list}>
				<Card title={'Месяц безлимит'} price={'1280 рублей'} />
				<Card title={'Год безлимит'} price={'1280 рублей'} />
				<Card title={'3 документа'} price={'1280 рублей'} />
				<Card title={'6 документов'} price={'1280 рублей'} />
				<Card title={'12 документов'} price={'1280 рублей'} />
				<Card title={'Год безлимит'} price={'1280 рублей'} />
			</div>
		</div>
	);
};

function Card({ title, price }) {
	return (
		<div className={s.card}>
			<p>{title}</p>
			<p>{price}</p>
			<p> Приобрести</p>
		</div>
	);
}
