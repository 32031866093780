import { useNavigate, Route, Routes, useHref } from 'react-router-dom';
import s from './users.module.css';
import { Input } from '../../../../common/input/input';
// import { Button } from '../../../common/button/button';
import { ReactComponent as SearchIcon } from '../../../../../img/searchIcon.svg';
import { ReactComponent as Arrow } from '../../../../../img/arrow2.svg';
import { useRef, useState } from 'react';
import { useOutsideClick } from '../../../../../core/hooks/useOutsideClick';
import { User } from './user/user';
import { DropdownMenu } from '../../../../common/dropdownMenu/dropdownMenu';

export const Users = () => {
	const navigate = useNavigate();
	const href = useHref();

	const handlerUserNavigate = (userId) => {
		navigate(`${userId}`);
	};

	return (
		<div className={s.container}>
			<div className={s.headRow}>
				<div>
					{href === '/adminOffice/users' ? (
						<h3 className={s.title}>Пользователи</h3>
					) : (
						<button className={s.btnBack} onClick={() => navigate(-1)}>
							<Arrow /> Вернуться
						</button>
					)}

					<div className={s.search}>
						<Input
							classes={s.input}
							// inputValue={}
							// changeFunction={}
							// clickFunction={}
							// keyDownEnterFunction={}
							placeholder={'Поиск'}
						/>

						<SearchIcon className={s.searchIcon} />
					</div>
				</div>

				<div>
					<p>Сортировать: по дате</p>

					<p>Вся история</p>
				</div>
			</div>

			<div className={s.content}>
				{href === '/adminOffice/users' && (
					<table className={s.table}>
						<thead>
							<tr className={s.row}>
								<th>ФИО</th>
								<th>ID</th>
								<th>Баланс</th>
								<th>Дата регистрации</th>
								<th>Тип</th>
								<th>Статус</th>
								<th>Действие</th>
							</tr>
						</thead>

						<tbody>
							<Row
								userName={'Иванов Иван Иванович'}
								id={1}
								balance={12235}
								date={'11.11.1111'}
								type={'Физ. лицо'}
								status={'Действующий'}
								handlerNavigate={handlerUserNavigate}
							/>
							<Row
								userName={'Иванов Иван Иванович'}
								id={2}
								balance={12235}
								date={'11.11.1111'}
								type={'Физ. лицо'}
								status={'Действующий'}
								handlerNavigate={handlerUserNavigate}
							/>
							<Row
								userName={'Иванов Иван Иванович'}
								id={3545646}
								balance={12235}
								date={'11.11.1111'}
								type={'Физ. лицо'}
								status={'Действующий'}
								handlerNavigate={handlerUserNavigate}
							/>
						</tbody>
					</table>
				)}

				<Routes>
					<Route path=':userId' element={<User />} />
				</Routes>
			</div>
		</div>
	);
};

function Row({ userName, id, balance, date, type, status, handlerNavigate }) {
	return (
		<tr className={s.row}>
			<td>{userName}</td>
			<td>{id}</td>
			<td>{balance} ₽</td>
			<td>{date}</td>
			<td>{type}</td>
			<td>{status}</td>
			<td>
				<DropdownMenu
					list={[
						{
							name: 'Перейти в профиль',
							clickFunction: () => handlerNavigate(id),
						},
						{ name: 'Передать документ' },
						{ name: 'Пополнить баланс' },
						{ name: 'Заблокировать' },
						{ name: 'Удалить' },
					]}
				/>
			</td>
		</tr>
	);
}
