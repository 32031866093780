import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import s from './toolbar.module.css';
import { BlockTypeEditorControls } from './blockEditorControls/blockEditorControls';
import { InlineEditorControls } from './inlineEditorControls/inlineEditorControls';
import { AlignmentEditorControls } from './alignmentEditorControls/AlignmentEditorControls';
import { ImgEditorControl } from './imgEditorControl/imgEditorControl';
import { EditorContext } from '../../content/contentContainer';
// import { creatorEditorStateWithEntity } from '../../../../../core/utils/functions/creatorEditorStateWithEntity';
import { addFieldToTheList } from '../../../../../store/features/document/documentSlice';
import { readdEntityToEditorState } from '../../../../../core/utils/functions/readdEntityToEditorState';
// import { creatorEditorStateWithImg } from '../../../../../core/utils/functions/creatorEditorStateWithImg';
import { creatorEditorStateWithEntity } from '../../../../../core/utils/functions/creatorEditorStateWithEntity';
import { convertToRaw } from 'draft-js';

export const Toolbar = () => {
	const dispatch = useDispatch();

	const { editorState, handlerEditorState, dispatchCurrentContent, doc } =
		useContext(EditorContext);

	const addSignature = () => {
		const isSignature = doc.fieldList.find(
			(field) => field.type === 'signature'
		);

		let newEditorState = undefined;

		if (isSignature === undefined) {
			newEditorState = creatorEditorStateWithEntity(
				editorState,
				'signature',
				'Подпись'
				// `${logo}`
			);

			dispatch(
				addFieldToTheList({
					key: editorState.getCurrentContent().getLastCreatedEntityKey(),
					name: 'Подпись',
					type: 'signature',
					src: '',
					width: '100px',
					height: '100px',
				})
			);
		} else {
			newEditorState = readdEntityToEditorState(
				editorState,
				isSignature.name,
				isSignature.key
			);
		}
		// const newEditorState = creatorEditorStateWithImg(editorState);

		handlerEditorState(newEditorState);
		dispatchCurrentContent(newEditorState);
	};

	return (
		<div className={`${s.container} RichEditor-root`}>
			<InlineEditorControls />

			<BlockTypeEditorControls />

			<AlignmentEditorControls />

			<button style={{ margin: '0 20px 0 0' }} onClick={addSignature}>
				Подпись
			</button>

			<ImgEditorControl />
		</div>
	);
};
