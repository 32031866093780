// import { EditorState, Modifier, SelectionState, convertToRaw } from 'draft-js';
import s from './creatorPDF.module.css';
import { stateToHTML } from 'draft-js-export-html';
// import htmlToPdfMake from 'html-to-pdfmake';
// import draftToHtml from 'draftjs-to-html';
// import pdfMake from 'pdfmake/build/pdfmake';
// import vfs from '../../../fonts/pdfMake/build/vfs_fonts';
// import { pdfMakeFonts } from '../../../fonts/pdfMake/pdfMakeFonts';
import { templateApi } from '../../../api/api';

export const CreatorPDF = ({ editorState, text }) => {
	const handleGeneratePDF = () => {
		// pdfMake.vfs = vfs.pdfMake.vfs;
		// pdfMake.fonts = pdfMakeFonts;

		// const strHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));

		const options = {
			blockStyleFn: customBlockStyle,
		};

		const html = stateToHTML(editorState.getCurrentContent(), options);

		// console.log('strHTML', strHTML);
		// console.log('HTML', html);

		////////////////////////////////////
		templateApi.createPDF(html).then((blob) => {
			const url = URL.createObjectURL(blob.data);
			window.open(url);
		});

		// createPDFMake(html);
	};

	function customBlockStyle(contentBlock) {
		const data = contentBlock.getData();
		const alignBlock = data.get('text-align');

		if (alignBlock) {
			return { style: { 'text-align': alignBlock } };
		}
	}

	// function createPDFMake(html) {
	// 	const contentForPDF = htmlToPdfMake(
	// 		// strHTML,
	// 		html,
	// 		{
	// 			defaultStyles: {
	// 				p: {
	// 					margin: [0, 0, 0, 0],
	// 				},
	// 			},
	// 		}
	// 	);

	// 	// console.log('contentForPDF', contentForPDF);

	// 	const content = contentForPDF
	// 		.filter((block) => block.text !== ' ')
	// 		.map((block) => {
	// 			if (block.text === '') {
	// 				block.text = ' ';
	// 			}

	// 			const blockWithImg = block.stack?.find((el) => el.nodeName === 'IMG');
	// 			const blockWithText = block.stack?.find((el) => el.text);

	// 			if (blockWithImg && blockWithText) {
	// 				const newBlockStack = block.stack.map((el) => {
	// 					if (el.text) {
	// 						el.width = 'auto';
	// 						el.margin = [0, 64, 0, 0];
	// 					}
	// 					return el;
	// 				});

	// 				// console.log(newBlockStack);

	// 				let newBlock = {
	// 					table: {
	// 						widths: ['auto', '*', '*'],
	// 						body: [
	// 							[
	// 								{
	// 									columns: newBlockStack,
	// 								},
	// 								'',
	// 								'',
	// 							],
	// 						],
	// 					},
	// 					defaultStyle: {
	// 						margin: [10, 5, 20, 5],
	// 					},

	// 					layout: 'noBorders',
	// 				};

	// 				switch (newBlockStack[0].alignment) {
	// 					case 'center':
	// 						newBlock.table.widths = ['*', 'auto', '*'];
	// 						newBlock.table.body = [
	// 							[
	// 								'',
	// 								{
	// 									columns: newBlockStack,
	// 								},
	// 								'',
	// 							],
	// 						];

	// 						break;

	// 					case 'right':
	// 						newBlock.table.widths = ['*', '*', 'auto'];
	// 						newBlock.table.body = [
	// 							[
	// 								'',
	// 								'',
	// 								{
	// 									columns: newBlockStack,
	// 								},
	// 							],
	// 						];

	// 						break;
	// 				}

	// 				// console.log(newBlock);
	// 				return newBlock;
	// 			}

	// 			return block;
	// 		});

	// 	// console.log('content', content);

	// 	pdfMake
	// 		.createPdf({
	// 			content: content,
	// 			defaultStyle: {
	// 				// font: 'TimesNewRoman',
	// 				columnGap: 3,
	// 			},
	// 		})
	// 		.open();
	// }

	return (
		<div className={s.container} onClick={handleGeneratePDF}>
			{text}
		</div>
	);
};
