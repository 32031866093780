import { useContext } from 'react';
import { EditorState, Modifier } from 'draft-js';
import { ALIGNMENT_TYPES } from '../../config/config';
import { EditorControlItem } from '../editorControlItem/editorControlItem';
import { EditorContext } from '../../../content/contentContainer';

export const AlignmentEditorControls = () => {
	const { editorState, setEditorState } = useContext(EditorContext);

	const selection = editorState.getSelection();
	const blockData = editorState
		.getCurrentContent()
		.getBlockForKey(selection.getStartKey())
		.getData()
		.get('text-align');

	const changeAlignment = (newAlignment) => {
		const contentState = editorState.getCurrentContent();
		const selectionState = editorState.getSelection();

		const newEditorState = EditorState.push(
			editorState,
			Modifier.setBlockData(contentState, selectionState, {
				'text-align': newAlignment,
			}),
			'change-block-data'
		);

		setEditorState(newEditorState);
	};

	return (
		<div className='RichEditor-controls'>
			{ALIGNMENT_TYPES.map((type) => (
				<EditorControlItem
					key={type.label}
					style={type.style}
					isActive={blockData === type.style}
					onToggle={() => changeAlignment(type.style)}>
					{type.label}
				</EditorControlItem>
			))}
		</div>
	);
};
