import { createSlice, current } from '@reduxjs/toolkit';
import { getServiceList } from './thunks';

const initialState = {
	serviceList: [],
	dirList: [],
};

export const servicesSlice = createSlice({
	name: 'services',
	initialState,

	reducers: {
		setServiceList: (state, action) => {
			state.serviceList = action.payload;
		},

		setDirList: (state, action) => {
			state.dirList = action.payload;
		},

		setFieldKey: (state, action) => {
			const currentDoc = state.serviceList.find(
				(doc) => doc.id === action.payload.fileId
			);

			const currentField = currentDoc.fieldList.find(
				(field) => field.key === action.payload.field.key
			);
			currentField.key = action.payload.newKey;

			const entityMapValues = Object.values(currentDoc.editorContent.entityMap);
			const currentEntity = entityMapValues.find(
				(entity) => entity.data.key === action.payload.field.key
			);
			currentEntity.data.key = action.payload.newKey;
		},
	},

	extraReducers: {
		[getServiceList.rejected]: (state, action) => console.log(action.payload),
	},
});

export const { setServiceList, setDirList, setFieldKey } =
	servicesSlice.actions;
export default servicesSlice.reducer;
