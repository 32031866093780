import { ModalWindow } from '../modalWindow/modalWindow';
import s from './requestResultWindow.module.css';

export const RequestResultWindow = ({ clickFunction, message }) => {
	return (
		<ModalWindow
			clickFunction={clickFunction}
			content={<h2 className={s.container}>{message}</h2>}
		/>
	);
};
