import { useEffect } from 'react';
import { useNavigate, Route, Routes, useHref } from 'react-router-dom';
import s from './adminOffice.module.css';
import { useAuth } from '../../../../core/hooks/useAuth';
import { MainMenu } from '../../../common/mainMenu/mainMenu';
import { Navigation } from '../../../common/navigation/navigation';
import { Section } from '../../../common/section/section';
import { MenuItem } from '../../../common/menuItem/menuItem';
import { ReactComponent as Man } from '../../../../img/icon/man.svg';
import { ReactComponent as MyPay } from '../../../../img/icon/myPay.svg';
import { ReactComponent as BookmarkIcon } from '../../../../img/bookmarkIcon.svg';
import { ReactComponent as Subscription } from '../../../../img/icon/subscription.svg';
import { ReactComponent as Settings } from '../../../../img/icon/setting.svg';
import { ReactComponent as Support } from '../../../../img/icon/support.svg';
import { Moderation } from './moderation/moderation';
import { Library } from './library/library';
import { Users } from './users/users';
import { Maintenance } from './maintenance/maintenance';
// import { SupportContent } from './supportContent/supportContent';

export const AdminOffice = () => {
	const navigate = useNavigate();
	const { isAuth } = useAuth();

	const href = useHref();

	const handlerNavigation = (path) => navigate(path);

	useEffect(() => {
		if (!isAuth) {
			navigate('/');
		}
	}, [isAuth]);

	// console.log(href);
	return (
		<div className={s.container}>
			<Navigation
				linkList={[
					{
						text: 'Главная',
						path: '/',
					},
					{
						text: 'Личный кабинет',
					},
				]}
			/>

			<Section className={s.section}>
				<MainMenu
					classes={s.categoryList}
					list={
						<>
							<div className={s.categoryContainer}>
								<MenuItem
									classes={s.category}
									icon={<Man />}
									name={'Модерация'}
									clickFunction={() => handlerNavigation('moderation')}
								/>

								<div
									style={
										href === '/adminOffice/moderation'
											? { backgroundColor: '#fff' }
											: {}
									}
									className={s.bookmark}></div>
							</div>

							<div className={s.categoryContainer}>
								<MenuItem
									classes={s.category}
									icon={<MyPay />}
									name={'Библиотека'}
									clickFunction={() => handlerNavigation('library')}
								/>

								<div
									style={
										href === '/adminOffice/library'
											? { backgroundColor: '#fff' }
											: {}
									}
									className={s.bookmark}></div>
							</div>

							<div className={s.categoryContainer}>
								<MenuItem
									classes={s.category}
									icon={<BookmarkIcon />}
									name={'Пользователи'}
									clickFunction={() => handlerNavigation('users')}
								/>

								<div
									style={
										/adminOffice\/users\/?.*/.test(href)
											? { backgroundColor: '#fff' }
											: {}
									}
									className={s.bookmark}></div>
							</div>

							<div className={s.categoryContainer}>
								<MenuItem
									classes={s.category}
									icon={<Support />}
									name={'Поддержка'}
									clickFunction={() => handlerNavigation('support')}
								/>

								<div
									style={
										/adminOffice\/support\/?.*/.test(href)
											? { backgroundColor: '#fff' }
											: {}
									}
									className={s.bookmark}></div>
							</div>
						</>
					}
				/>
			</Section>

			<div className={s.content}>
				<Routes>
					<Route path='moderation' element={<Moderation />} />
					<Route path='library' element={<Library />} />
					<Route path='users/*' element={<Users />} />
					<Route path='support/*' element={<Maintenance />} />
				</Routes>
			</div>
		</div>
	);
};
