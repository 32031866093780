import s from './link.module.css';
import { useNavigate } from 'react-router-dom';

export const Link = (props) => {
	const navigate = useNavigate();
	return (
		<a
			href={props.href}
			target={props.target}
			className={`${s.link} ${props.className}`}
			onClick={() => navigate(props.path)}>
			{props.children}
		</a>
	);
};
