import { useState } from 'react';
import s from './paymentByPay.module.css';
import { ReactComponent as SberPay } from '../../../../../../../img/sberPay.svg';
import { ReactComponent as TinkPay } from '../../../../../../../img/tinkPay.svg';
import { ReactComponent as AlfaPay } from '../../../../../../../img/alfaPay.svg';

export const PaymentByPay = () => {
	const SBER_PAY = 'SBER_PAY';
	const TINKOFF_PAY = 'TINKOFF_PAY';
	const ALFA_PAY = 'ALFA_PAY';

	const [methodPay, setMethodPay] = useState('');

	return (
		<ul className={s.list}>
			<MethodPay
				icon={<SberPay />}
				name={'СБЕР pay'}
				method={SBER_PAY}
				methodPay={methodPay}
				clickFn={() => setMethodPay(SBER_PAY)}
			/>
			<MethodPay
				icon={<TinkPay />}
				name={'Тинькофф pay'}
				method={TINKOFF_PAY}
				methodPay={methodPay}
				clickFn={() => setMethodPay(TINKOFF_PAY)}
			/>
			<MethodPay
				icon={<AlfaPay />}
				name={'Альфа pay'}
				method={ALFA_PAY}
				methodPay={methodPay}
				clickFn={() => setMethodPay(ALFA_PAY)}
			/>
		</ul>
	);
};

function MethodPay({ icon, name, method, methodPay, clickFn }) {
	return (
		<li
			className={`${s.method} ${method === methodPay && s.active}`}
			onClick={clickFn}>
			{icon}
			{name}
		</li>
	);
}
