import { useEffect, useState } from 'react';
import s from './signaturePage.module.css';
import { Signature } from '../../common/signature/signature';
import { useHref } from 'react-router-dom';
import { userDocApi } from '../../../api/api';
import { useDispatch } from 'react-redux';
import { setStateDocument } from '../../../store/features/document/documentSlice';

export const SignaturePage = () => {
	const dispatch = useDispatch();

	const href = useHref();

	const [isError, setIsError] = useState(false);

	useEffect(() => {
		userDocApi
			.getUserDoc({ href, update: false })
			.then((res) => {
				console.log(res.data);

				dispatch(setStateDocument(res.data));
			})
			.catch((err) => {
				console.log(err);
				setIsError(err.response.data.error);
			});
	}, []);

	return (
		<div className={s.container}>
			{/* <Signature /> */}
			{isError ? <p>{isError}</p> : <Signature />}
		</div>
	);
};
