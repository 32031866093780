import { createAsyncThunk } from '@reduxjs/toolkit';
import { authApi, userApi, verifyApi } from '../../../api/api';
import { setUser, setIsVerifyPhone } from './userSlice';
import { createCookie } from '../../../core/utils/functions/createCookie';
import { formatPhoneNumberForDataBase } from '../../../core/utils/functions/formatPhoneNumber/formatPhoneNumberForDataBase';

export const getUserData = createAsyncThunk(
	'user/getUserData',
	async (userToken, { rejectWithValue, dispatch }) => {
		const res = await authApi.getUserData(userToken);
		// console.log(res);
		dispatch(setUser(res.data));
	}
);

export const authByEmail = createAsyncThunk(
	'user/authByEmail',
	async ({ email, password, isRemember, setError }, { dispatch }) => {
		// console.log(email);
		authApi
			.emailAuth(email, password)
			.then((res) => {
				// console.log(res.data);
				if (isRemember) {
					const cookieTimeLive = 60 * 60 * 24 * 7; // сек * мин * час * дн
					createCookie('userAuth', res.data.token, cookieTimeLive);
					// document.cookie = `userAuth = ${res.data.token}; max-age = ${cookieTimeLive}`;
				}

				dispatch(setUser(res.data.user));
			})
			.catch((err) => {
				// console.log(err);
				setError(err.response.data.error);
			});
	}
);

export const authByPhone = createAsyncThunk(
	'user/authByPhone',
	async ({ phone, smsCode, isRemember, setError }, { dispatch }) => {
		authApi
			.phoneAuth(phone, smsCode)
			.then((res) => {
				// console.log(res.data);

				if (isRemember) {
					// console.log(res.data.token);
					const cookieTimeLive = 60 * 60 * 24 * 7;
					createCookie('userAuth', res.data.token, cookieTimeLive);
				}

				dispatch(setUser(res.data.user));
			})
			.catch((err) => {
				console.log(err);
				setError(err.response.data.error);
			});
	}
);

export const getSMSCode = createAsyncThunk(
	'user/getSmsCode',
	async ({ phone, setError, openActionWindow }) => {
		setError('');

		authApi
			.getSMSCode(phone)
			.then((res) => {
				// console.log(res);

				if (res.status === 200) {
					openActionWindow();
				}
			})
			.catch((err) => {
				console.log('err', err);
				setError(err.response.data.error);
			});
	}
);

export const userRegistration = createAsyncThunk(
	'user/userRegistration',
	async (
		{
			firstName,
			lastName,
			middleName,
			email,
			phone,
			password,
			setIsConfirmationEmailSent,
			setError,
		},
		{ dispatch }
	) => {
		userApi
			.register({
				firstName,
				lastName,
				middleName,
				email,
				phone,
				password,
			})
			.then((res) => {
				// console.log(res);
				setIsConfirmationEmailSent(true);
			})
			.catch((err) => {
				console.log('err', err);
				setError(err.response.data.error);
			});
	}
);

export const verifySmsCode = createAsyncThunk(
	'user/verifySmsCode',
	async ({ phone, smsCode, setError }, { dispatch }) => {
		setError('');

		verifyApi
			.verifySms({ phone, sms: smsCode })
			.then((res) => {
				// console.log(res);

				if (res.status === 200) {
					dispatch(setIsVerifyPhone(res.data.isVerifyPhone));
				}
			})
			.catch((err) => {
				console.log('err', err);
				setError(err.response.data.error);
			});
	}
);

export const verifyEmail = createAsyncThunk(
	'user/verifyEmail',
	async (
		{ email, setError = () => {}, openInformWindow = () => {} },
		{ dispatch }
	) => {
		setError('');

		verifyApi
			.verifyEmail(email)
			.then((res) => {
				// console.log(res);

				if (res.status === 200) {
					openInformWindow();
				}
			})
			.catch((err) => {
				console.log('err', err);
				setError(err.response.data.error);
			});
	}
);

export const resetPassword = createAsyncThunk(
	'user/authByEmail',
	async ({ email, setError = () => {}, openInformWindow = () => {} }) => {
		setError('');

		userApi
			.resetPassword(email)
			.then((res) => {
				if (res.status === 200) {
					openInformWindow();
				}
			})
			.catch((err) => {
				console.log(err);
				setError(err.response.data.error);
			});
	}
);
