import s from './userMenu.module.css';
import { ReactComponent as UserIcon } from '../../../../img/userIcon.svg';
import { useState } from 'react';
import { MenuCard } from './menuCard/menuCard';

export const UserMenu = ({ userData }) => {
	const [menuOpen, setMenuOpen] = useState(false);

	// console.log(userData);
	return (
		<div className={s.container}>
			<div className={s.userBlock} onClick={() => setMenuOpen(!menuOpen)}>
				{userData.photo ? 'userPhoto' : <UserIcon className={s.icon} />}

				<span className={menuOpen ? `${s.active}` : ''}>
					{userData.firstName}
				</span>
			</div>

			{menuOpen ? <MenuCard setMenuOpen={setMenuOpen} /> : <></>}
		</div>
	);
};
