import { useContext } from 'react';
import s from './serviceName.module.css';
import { WindowWidthContext } from '../../../../../app/app';
import { ReactComponent as ArrowFlag } from '../../../../../../img/linkArrowFlag.svg';

export const ServiceName = ({ i, category, name, icon, clickFunction }) => {
	const { windowWidth, windowWidthTablet } = useContext(WindowWidthContext);
	return (
		<div className={s.container} onClick={clickFunction}>
			<p>{i}.</p>

			<div>
				{icon}

				<div className={s.serviceNameWrp}>
					<h5>{category}</h5>

					<h4> {name}</h4>
				</div>
			</div>

			{windowWidth <= windowWidthTablet && <ArrowFlag />}
		</div>
	);
};
