import s from './serviceBlock.module.css';
import { MenuItem } from '../../../common/menuItem/menuItem';
import { ReactComponent as AllCategories } from '../../../../img/icon/allCategories.svg';
import { ReactComponent as Damage } from '../../../../img/icon/law.svg';
import { ReactComponent as Home } from '../../../../img/icon/home.svg';
import { ReactComponent as Car } from '../../../../img/icon/car.svg';
import { ReactComponent as Family } from '../../../../img/icon/family.svg';
import { ReactComponent as Money } from '../../../../img/icon/money.svg';
import { ReactComponent as Work } from '../../../../img/icon/work.svg';
import { MainMenu } from '../../../common/mainMenu/mainMenu';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { WindowWidthContext } from '../../../app/app';

export const ServiceBlock = () => {
	const navigate = useNavigate();
	const { windowWidth, windowWidthTablet } = useContext(WindowWidthContext);

	const handlerNavigate = (path) => {
		navigate(path);
	};
	return (
		<MainMenu
			classes={s.container}
			list={
				<>
					{windowWidth <= windowWidthTablet && (
						<MenuItem
							classes={s.service}
							icon={<AllCategories />}
							name={'Весь список'}
						/>
					)}

					<MenuItem
						classes={s.service}
						icon={<Damage />}
						name={'Ущерб'}
						clickFunction={() => handlerNavigate('categories/damage')}
					/>

					<MenuItem
						classes={s.service}
						icon={<Home />}
						name={'Недвижимость и имущество'}
						clickFunction={() => handlerNavigate('categories/property')}
					/>
					<MenuItem
						classes={s.service}
						icon={<Car />}
						name={'Транспорт'}
						clickFunction={() => handlerNavigate('categories/transport')}
					/>
					<MenuItem
						classes={s.service}
						icon={<Family />}
						name={'Семья и дети'}
						clickFunction={() => handlerNavigate('categories/family')}
					/>
					<MenuItem
						classes={s.service}
						icon={<Money />}
						name={'Пособия и выплаты'}
						clickFunction={() =>
							handlerNavigate('categories/benefitsAndPayments')
						}
					/>
					<MenuItem
						classes={s.service}
						icon={<Work />}
						name={'Работа'}
						clickFunction={() => handlerNavigate('categories/work')}
					/>
				</>
			}
		/>
	);
};
