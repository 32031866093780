import s from './purchases.module.css';
import { Input } from '../../../../common/input/input';
// import { Button } from '../../../common/button/button';
import { ReactComponent as SearchIcon } from '../../../../../img/searchIcon.svg';
import { ReactComponent as Icon } from '../../../../../img/icon/rub.svg';
// import { ReactComponent as ArrowFlag } from '../../../../../img/linkArrowFlag.svg';
import { ServiceName } from './serviceName/serviceName';
import { ServiceChangeHistory } from './serviceChangeHistory/serviceChangeHistory';
import { ServiceStatus } from './serviceStatus/serviceStatus';

export const Purchases = () => {
	return (
		<div className={s.container}>
			<div className={s.headRow}>
				<div>
					<h3 className={s.title}>Мои покупки</h3>

					<div className={s.search}>
						<Input
							classes={s.input}
							// inputValue={}
							// changeFunction={}
							// clickFunction={}
							// keyDownEnterFunction={}
							placeholder={'Поиск'}
						/>

						<SearchIcon className={s.searchIcon} />
					</div>
				</div>

				<div>
					<p>Сортировать: по дате</p>

					<p>Вся история</p>
				</div>
			</div>

			<div className={s.table}>
				<div className={s.row}>
					<p>Наименование</p>
					<p>История изменений</p>
					<p>Статус</p>
				</div>

				<Row
					i={1}
					category={'Товары и услуги'}
					name={'Претензия на возврат денежных средств'}
					icon={<Icon />}
					listOfChange={[
						{ date: '10.09.2023 12:31', status: 'Заполнение анкеты' },
						{ date: '10.09.2023 12:31', status: 'Заполнение анкеты' },
						{ date: '10.09.2023 12:31', status: 'Заполнение анкеты' },
					]}
				/>

				<Row
					i={2}
					category={'Товары и услуги'}
					name={'Претензия на возврат денежных средств'}
					icon={<Icon />}
					listOfChange={[
						{ date: '10.09.2023 12:31', status: 'Заполнение анкеты' },
						{ date: '10.09.2023 12:31', status: 'Заполнение анкеты' },
						{ date: '10.09.2023 12:31', status: 'Заполнение анкеты' },
					]}
				/>
			</div>
		</div>
	);
};

function Row({ i, category, name, icon, listOfChange }) {
	return (
		<div className={s.row}>
			<ServiceName i={i} category={category} name={name} icon={icon} />

			<ServiceChangeHistory listOfChange={listOfChange} />

			<ServiceStatus />
		</div>
	);
}
