import { useRef, useState } from 'react';
import s from './signature.module.css';
import { Button } from '../button/button';
import { useDispatch, useSelector } from 'react-redux';
import { selectDocument } from '../../../store/selectors';
import {
	setFieldSrc,
	setStateDocument,
} from '../../../store/features/document/documentSlice';
import {
	createUserDocument,
	updateUserDocument,
} from '../../../store/features/document/thunks';
import { insertSignatureIntoDoc } from '../../../core/utils/functions/insertSignatureIntoDoc';
import { creatorDocAndStateFromTemplate } from '../../../core/utils/functions/creatorDocAndStateFromTemplate';
import { RequestResultWindow } from '../requestResultWindow/requestResultWindow';

export const Signature = () => {
	const dispatch = useDispatch();
	const signature = useRef(null);

	const userDoc = useSelector(selectDocument);

	const [isDrawing, setIsDrawing] = useState(false);
	const [requestResult, setRequestResult] = useState('');

	const draw = (e) => {
		if (!isDrawing) return;

		const canvas = signature.current;
		const ctx = canvas.getContext('2d');

		ctx.lineWidth = 0.7;
		ctx.lineCap = 'round';
		ctx.strokeStyle = 'blue';

		const canvasPosition = canvas.getBoundingClientRect();
		let x, y;

		if (e.type === 'touchmove') {
			e.preventDefault();
			const touch = e.touches[0];
			x = touch.clientX - canvasPosition.left;
			y = touch.clientY - canvasPosition.top;
		} else {
			x = e.clientX - canvasPosition.left;
			y = e.clientY - canvasPosition.top;
		}

		ctx.lineTo(x, y);
		ctx.stroke();
		ctx.beginPath();
		ctx.moveTo(x, y);
	};

	const startDrawing = () => {
		// console.log(e);

		const canvas = signature.current;
		const ctx = canvas.getContext('2d');
		ctx.beginPath();
		setIsDrawing(true);
	};

	const stopDrawing = () => {
		// console.log('stop draw');
		// console.log(e);

		const canvas = signature.current;
		const ctx = canvas.getContext('2d');
		ctx.closePath();
		setIsDrawing(false);
	};

	const clearFieldSignature = () => {
		const canvas = signature.current;
		const ctx = canvas.getContext('2d');
		ctx.clearRect(0, 0, canvas.width, canvas.height);
	};

	const signDoc = () => {
		const signatureField = userDoc.fieldList.find(
			(field) => field.type === 'signature'
		);

		if (signatureField === undefined) {
			console.log('signatureField undefined');

			setRequestResult('Поле подпись не найдено!');
			return;
		}

		dispatch(
			setFieldSrc({
				key: signatureField.key,
				src: signature.current.toDataURL(),
			})
		);
		const docAndState = creatorDocAndStateFromTemplate(userDoc);

		const updateDoc = insertSignatureIntoDoc(
			docAndState.doc,
			signature.current.toDataURL()
		);

		dispatch(setStateDocument(updateDoc));

		dispatch(updateUserDocument())
			.then((res) => {
				console.log(res);

				setRequestResult('Успешно!');
			})
			.catch((err) => {
				console.log(err);

				setRequestResult('Произошла ошибка!');
			});
	};

	return (
		<div className={s.container}>
			<canvas
				ref={signature}
				className={s.signature}
				width={320}
				height={160}
				onTouchStart={startDrawing}
				onTouchEnd={stopDrawing}
				onTouchMove={draw}
				onTouchCancel={stopDrawing}
				onMouseDown={startDrawing}
				onMouseUp={stopDrawing}
				onMouseMove={draw}
				onMouseOut={stopDrawing}
			/>

			<div className={s.btnContainer}>
				<Button text={'Подписать'} clickFunction={signDoc} />

				<Button text={'Очистить'} clickFunction={clearFieldSignature} />
			</div>

			{requestResult && (
				<RequestResultWindow
					clickFunction={() => window.close()}
					message={requestResult}
				/>
			)}
		</div>
	);
};
