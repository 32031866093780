import { CircleWithShadow } from '../circleWithShadow/circleWithShadow';
import s from './menuItem.module.css';

export const MenuItem = ({ classes, icon, name, clickFunction }) => {
	return (
		<div className={`${s.wrp} ${classes}`} onClick={clickFunction}>
			<CircleWithShadow classes={s.iconBlock} icon={icon} />

			<div className={s.textContainer}>
				<p className={s.text}>{name}</p>
			</div>
		</div>
	);
};
