import { Route, Routes } from 'react-router-dom';
import { ServiceCard } from '../../../../common/serviceCard/serviceCard';
import { ServicePage } from '../../../servicePage/servicePage';
import s from './favorites.module.css';

export const Favorites = ({ handlerNavigation }) => {
	const handlerClick = () => {
		handlerNavigation('damage/serviceName');
	};

	return (
		<div className={s.container}>
			<div className={s.headRow}>
				<h3 className={s.title}>Избранное</h3>

				<p>Сортировать: по дате</p>
			</div>

			<div className={s.list}>
				<ServiceCard classes={s.serviceCard} clickFunction={handlerClick} />
				<ServiceCard classes={s.serviceCard} />
				<ServiceCard classes={s.serviceCard} />
				<ServiceCard classes={s.serviceCard} />
				<ServiceCard classes={s.serviceCard} />
			</div>

			{/* <Routes>
				<Route path='serviceName' element={<ServicePage />} />
			</Routes> */}
		</div>
	);
};
