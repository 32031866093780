import { Header } from '../header/header';
import s from './screenHeightContainer.module.css';

export const ScreenHeightContainer = ({ children }) => {
	// console.log('ScreenHeightContainer');

	return (
		<div className={s.container}>
			{/* <Header /> */}

			{children}
		</div>
	);
};
