import { useHref } from 'react-router-dom';
import s from './categoriesMenu.module.css';
import { ReactComponent as Damage } from '../../../../img/icon/law.svg';

export const CategoriesMenu = () => {
	const href = useHref();

	return (
		<div
			style={
				href === '/editorPage'
					? { padding: '1.25rem 50px 0', maxWidth: 'none' }
					: {}
			}
			className={s.container}>
			<Item icon={<Damage />} text={'Ущерб'} />
			<Item icon={<Damage />} text={'Ущерб'} />
			<Item icon={<Damage />} text={'Ущерб'} />
			<Item icon={<Damage />} text={'Ущерб'} />
			<Item icon={<Damage />} text={'Ущерб'} />
			<Item icon={<Damage />} text={'Ущерб'} />
			<Item icon={<Damage />} text={'Ущерб'} />
			<Item icon={<Damage />} text={'Ущерб'} />
			<Item icon={<Damage />} text={'Ущерб'} />
			<Item icon={<Damage />} text={'Ущерб'} />
			<Item icon={<Damage />} text={'Ущерб'} />
			<Item icon={<Damage />} text={'Ущерб'} />
		</div>
	);
};

function Item({ icon, text }) {
	return (
		<div className={s.item}>
			{icon}
			{text}
		</div>
	);
}
