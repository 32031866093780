import s from './newsBlock.module.css';
import img from '../../../../img/image.png';
import { useContext, useEffect, useState } from 'react';
import { WindowWidthContext } from '../../../app/app';
import { Button } from '../../../common/button/button';
import { Link } from '../../../common/link/link';
import { newsApi } from '../../../../api/api';

export const NewsBlock = () => {
	const [news, setNews] = useState([]);

	useEffect(() => {
		newsApi.getNews().then((res) => setNews(res.data));
	}, []);

	return (
		<div className={s.container}>
			<h3 className={`title`}>Новости</h3>

			<div className={s.wrp}>
				<BigCard />

				<div className={s.cards}>
					{news.map((el, i) => (
						<Card key={i} href={el.link} title={el.title} />
					))}
				</div>
			</div>
		</div>
	);
};

function BigCard() {
	const { windowWidth, windowWidthPhone } = useContext(WindowWidthContext);
	return (
		<>
			{windowWidth > windowWidthPhone ? (
				<div className={s.bigCard}>
					<div>
						<img src={img} alt='img' />

						<div className={s.cardInfo}>
							<p className={s.data}>
								Обновлено <time>30 ноября 2023, 11:13</time>
							</p>

							<h4>
								Умер Генри Киссинджер. За что он получил Нобелевскую премию
								мира.
							</h4>

							<p>
								В возрасте 100 лет умер Генри Киссинджер: за что получил
								Нобелевскую премию мира
							</p>

							<cite>Автор: Иванов Иван Иванович</cite>
						</div>
					</div>

					<p>
						В среду, 29 ноября, в своем доме в Коннектикуте в возрасте 100 лет
						умер бывший госсекретарь США Генри Киссинджер. В 1969 году он был
						назначен советником по национальной безопасности США и оставался на
						этой должности до 1975 года. С 1973 по 1977 год он также работал
						государственным секретарем.
					</p>
				</div>
			) : (
				<div className={s.bigCard}>
					<h4>
						Умер Генри Киссинджер. За что он получил Нобелевскую премию мира.
					</h4>

					<img src={img} alt='img' />

					<p>
						В возрасте 100 лет умер Генри Киссинджер: за что получил Нобелевскую
						премию мира
					</p>

					<p>
						В среду, 29 ноября, в своем доме в Коннектикуте в возрасте 100 лет
						умер бывший госсекретарь США Генри Киссинджер. В 1969 году он был
						назначен советником по национальной безопасности США и оставался на
						этой должности до 1975 года. С 1973 по 1977 год он также работал
						государственным секретарем.
					</p>

					<div>
						<div>
							<cite>Автор: Иванов Иван Иванович</cite>

							<p className={s.data}>
								Обновлено <time>30 ноября 2023, 11:13</time>
							</p>
						</div>

						<Button text='Подробнее' />
					</div>
				</div>
			)}
		</>
	);
}

function Card({ href, title }) {
	const { windowWidth, windowWidthPhone } = useContext(WindowWidthContext);

	const openNews = () => {
		window.open(href, '_blank');
	};

	return (
		<>
			{windowWidth > windowWidthPhone ? (
				<Link href={href} target={'_blank'} className={s.smallCard}>
					<img src={img} alt='img' />

					<div>
						<h6>{title}</h6>

						<p className={s.data}>
							Обновлено <time>30 ноября 2023, 11:13</time>
						</p>
					</div>
				</Link>
			) : (
				<div className={s.smallCard}>
					<h6>{title}</h6>

					<div>
						<p className={s.data}>
							Обновлено <time>30 ноября 2023, 11:13</time>
						</p>

						<Button text='Подробнее' clickFunction={openNews} />
					</div>
				</div>
			)}
		</>
	);
}
