import { useRef } from 'react';
import s from './serviceCard.module.css';
import img from '../../../img/image.png';
import { ReactComponent as Rub } from '../../../img/icon/rub.svg';
import { Button } from '../button/button';
import { ReactComponent as BookmarkIcon } from '../../../img/bookmarkIcon.svg';

export const ServiceCard = ({
	style,
	classes,
	clickFunction,
	imgUrl = img,
	icon,
	category,
	serviceName,
	description,
	price,
}) => {
	const bookmarkIconRef = useRef();

	const toggleActive = (e) => {
		console.log(e);
		bookmarkIconRef.current.classList.toggle(`${s.active}`);
	};

	return (
		<div
			style={style}
			className={`${s.card} ${classes}`}
			onClick={clickFunction}>
			<div
				style={{
					// transition: `all ${props.transitionDuration}ms`,
					backgroundImage: `url(${imgUrl})`,
					// backgroundImage: `url(${img})`,
				}}
				className={`${s.img}`}>
				{/* <Button text={'Подробнее'} /> */}
			</div>

			<div
				// style={{ transition: `all ${props.transitionDuration}ms` }}
				className={s.info}>
				<div
					className={s.titleCardContainer}
					// style={{ transition: `all ${props.transitionDuration}ms` }}
				>
					<Rub
					// style={{ transition: `all ${props.transitionDuration}ms` }}
					/>
					{icon}

					<div
						// style={{ transition: `all ${props.transitionDuration}ms` }}
						className={s.titleCard}>
						<h6
							className={s.category}
							// style={{ transition: `all ${props.transitionDuration}ms` }}
						>
							{category}
							Товары и услуги
						</h6>

						<h4
							className={s.service}

							// style={{ transition: `all ${props.transitionDuration}ms` }}
						>
							Service Name {serviceName}
						</h4>
					</div>
				</div>

				<p
					className={s.description}
					// style={{ transition: `all ${props.transitionDuration}ms` }}
				>
					{description}
					Получите юридически грамотное заявления с требованием вернуть
					необоснованно потраченные деньги.
				</p>

				<p
					className={s.price}
					// style={{ transition: `all ${props.transitionDuration}ms` }}
				>
					{price}
					от 450 ₽
				</p>
			</div>

			<BookmarkIcon
				ref={bookmarkIconRef}
				// style={{ transition: `all ${props.transitionDuration}ms` }}
				onClick={toggleActive}
			/>
		</div>
	);
};
