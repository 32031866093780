import { createContext, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, useHref } from 'react-router-dom';
import s from './app.module.css';
import { Header } from '../common/header/header';
import { MainPage } from '../pages/mainPage/mainPage';
import { EditorPage } from '../pages/editorPage/editorPage';
import { CategoriesPage } from '../pages/categoriesPage/categoriesPage';
import { CategoriesPageMobile } from '../pages/categoriesPage/categoriesPageMobile/categoriesPageMobile';
import { FormDocumentPage } from '../pages/formDocumentPage/formDocumentPage';
import { getServiceList } from '../../store/features/services/thunks';
import { SignaturePage } from '../pages/signaturePage/signaturePage';
import { VerifyPage } from '../pages/verifyPage/verifyPage';
import { Footer } from '../common/footer/footer';
import { getUserData } from '../../store/features/user/thunks';
import { BottomMenuMobile } from '../common/bottomMenuMobile/bottomMenuMobile';
import { UserOfficePage } from '../pages/officePage/userOffice/userOffice';
import { HeaderMobile } from '../common/header/headerMobile/headerMobile';
import { UserOfficeMobilePage } from '../pages/officePage/userOffice/userOfficeMobile/userOfficeMobile';
import { AdminOffice } from '../pages/officePage/adminOffice/adminOffice';
import { ServicePage } from '../pages/servicePage/servicePage';

export const WindowWidthContext = createContext();

export const App = () => {
	const dispatch = useDispatch();

	const appRef = useRef(null);
	const mainRef = useRef(null);
	const headerRef = useRef(null);

	const href = useHref();

	const isUserAuth = document.cookie
		.split('; ')
		.find((row) => row.startsWith('userAuth='));

	if (isUserAuth !== undefined) {
		const userToken = isUserAuth.split('=')[1];

		dispatch(getUserData(userToken));
		// console.log(userToken);
	}

	const windowWidthContextValue = {
		windowWidth: window.innerWidth,
		windowWidthTablet: 900,
		windowWidthPhone: 685,
	};

	const selectComponent = (options) => {
		// const userOfficeReg = /\/userOffice.*/i;

		const isLargeScreen =
			windowWidthContextValue.windowWidth >
			windowWidthContextValue.windowWidthTablet;

		// const isNotLargeScreen =
		// 	windowWidthContextValue.windowWidth <=
		// 	windowWidthContextValue.windowWidthPhone;

		// if (href === '/editorPage') {
		// 	return options.editorPage;
		// }

		if (
			// (href === '/' && !isNotLargeScreen) ||
			// (userOfficeReg.test(href) && isLargeScreen)
			isLargeScreen
		) {
			return options.default;
		}

		if (
			// (href === '/' && isNotLargeScreen) ||
			// (userOfficeReg.test(href) && !isLargeScreen)
			!isLargeScreen
		) {
			return options.mobile;
		}

		return null;
	};

	useEffect(() => {
		// Change styles for main and app tags.
		if (headerRef.current.id === 'headerMobile') {
			mainRef.current.style.margin = '0 0 50px';
			appRef.current.style.overflowX = 'hidden';
		}

		dispatch(getServiceList());
	}, []);

	return (
		<WindowWidthContext.Provider value={windowWidthContextValue}>
			<div ref={appRef} className={s.container}>
				{selectComponent({
					// editorPage: <></>,
					default: <Header headerRef={headerRef} />,
					mobile: <HeaderMobile headerRef={headerRef} />,
				})}

				<main
					ref={mainRef}
					style={
						href === '/editorPage'
							? {
									padding: '0px 50px 30px',
									boxSizing: 'border-box',
							  }
							: {}
					}>
					<Routes>
						<Route index element={<MainPage />} />
						{windowWidthContextValue.windowWidth >
						windowWidthContextValue.windowWidthTablet ? (
							<Route path='categories/*' element={<CategoriesPage />} />
						) : (
							<Route path='categories/*' element={<CategoriesPageMobile />} />
						)}
						{windowWidthContextValue.windowWidth >
							windowWidthContextValue.windowWidthTablet && (
							<Route
								path='categories/:categoryName/:serviceName'
								element={<ServicePage />}
							/>
						)}

						<Route path='services/form' element={<FormDocumentPage />} />
						{windowWidthContextValue.windowWidth >
						windowWidthContextValue.windowWidthTablet ? (
							<Route path='userOffice/*' element={<UserOfficePage />} />
						) : (
							<Route path='userOffice/*' element={<UserOfficeMobilePage />} />
						)}
						<Route path='adminOffice/*' element={<AdminOffice />} />

						<Route path='sign/:signNumber' element={<SignaturePage />} />
						<Route path='verify/:verifyToken' element={<VerifyPage />} />
						<Route path='editorPage' element={<EditorPage />} />
					</Routes>
				</main>

				{selectComponent({
					editorPage: <></>,
					default: <Footer />,
					mobile: <BottomMenuMobile />,
				})}
			</div>
		</WindowWidthContext.Provider>
	);
};
